export const RAID_TYPE = {
  TBC_BT: "TBC_BT",
  TBC_SWP: "TBC_SWP",
  WOTLK_NAXX: "WOTLK_NAXX",
  WOTLK_EOE: "WOTLK_EOE",
  WOTLK_OB: "WOTLK_OB",
  WOTLK_VAULT: "WOTLK_VAULT",
  WOTLK_ULD: "WOTLK_ULD",
  WOTLK_TOC: "WOTLK_TOC",
  WOTLK_ONY: "WOTLK_ONY",
  WOTLK_ICC: "WOTLK_ICC",
  WOTLK_RS: "WOTLK_RS",
};

export const RAID_NAME = {
  [RAID_TYPE.WOTLK_NAXX]: { short: "NAXX", full: "Naxxramas", zhcn: "纳克萨玛斯"},
  [RAID_TYPE.WOTLK_EOE]: { short: "EOE", full: "The Eye of Eternity", zhcn: "永恒之眼" },
  [RAID_TYPE.WOTLK_OB]: { short: "OB", full: "The Obsidian Sanctum", zhcn: "黑曜石圣殿" },
  [RAID_TYPE.WOTLK_VAULT]: { short: "VAULT", full: "Vault of Archavon", zhcn: "阿尔卡冯的宝库" },
  [RAID_TYPE.WOTLK_ULD]: { short: "ULD", full: "Ulduar", zhcn: "奥达曼" },
  [RAID_TYPE.WOTLK_TOC]: { short: "TOC", full: "Trial of the Crusader", zhcn: "十字军的试炼" },
  [RAID_TYPE.WOTLK_ONY]: { short: "ONY", full: "Onyxia's Lair", zhcn: "奥妮克希亚的巢穴" },
  [RAID_TYPE.WOTLK_ICC]: { short: "ICC", full: "Icecrown Citadel", zhcn: "冰冠堡垒" },
  [RAID_TYPE.WOTLK_RS]: { short: "RS", full: "The Ruby Sanctum", zhcn: "红玉圣殿" },
  [RAID_TYPE.TBC_BT]: { short: "BT", full: "Black Temple", zhcn: "黑暗神殿" },
  [RAID_TYPE.TBC_SWP]: { short: "SWP", full: "Sunwell Plateau", zhcn: "太阳井之高地" },
};


export const ITEM_TYPE = {
  STRING: 1,
  ITEM_ID: 2,
};

export const EXTRA_RECORD_TYPE = {
  CREDIT: 1,
  EXPENSE: 2,
};

export const WOW_COLOR = {
  0: "#9d9d9d",
  1: "#fff",
  2: "#1eff00",
  3: "#0070dd",
  4: "#a335ee",
  5: "#ff8000",
  6: "#e6cc80",
  10: "#00ccff",
};

export const BONUS_TYPE = {
  TANK: 1,
  ADMIN: 2,
  OTHER: 3,
};

import {
  faShieldCross,
  faUserTie,
  faBullseye,
} from "@fortawesome/pro-solid-svg-icons";

export const BONUS_TYPE_SVG = {
  [BONUS_TYPE.TANK]: faShieldCross,
  [BONUS_TYPE.ADMIN]: faUserTie,
  [BONUS_TYPE.OTHER]: faBullseye,
};

export const BONUS_CALC_TYPE = {
  TYPE_FIXED: 1,
  TYPE_PERCENT: 2,
};

export const BONUS_SPLIT_TYPE = {
  TYPE_EACH: 1,
  TYPE_SPLIT: 2,
};
