{
  "34170": {
    "t": "Pantaloons of Calming Strife",
    "tc": "止战马裤",
    "s": "Legs, Cloth Armor",
    "q": 4,
    "id": "34170",
    "sc": "止战马裤",
    "i": "inv_pants_cloth_07"
  },
  "34386": {
    "t": "Pantaloons of Growing Strife",
    "tc": "激战长裤",
    "s": "Legs, Cloth Armor",
    "q": 4,
    "id": "34386",
    "sc": "激战长裤",
    "i": "inv_pants_cloth_14"
  },
  "34169": {
    "t": "Breeches of Natural Aggression",
    "tc": "自然侵攻护腿",
    "s": "Legs, Leather Armor",
    "q": 4,
    "id": "34169",
    "sc": "自然侵攻护腿",
    "i": "inv_pants_leather_23"
  },
  "34384": {
    "t": "Breeches of Natural Splendor",
    "tc": "壮丽自然长裤",
    "s": "Legs, Leather Armor",
    "q": 4,
    "id": "34384",
    "sc": "壮丽自然长裤",
    "i": "inv_pants_leather_23"
  },
  "34168": {
    "t": "Starstalker Legguards",
    "tc": "逐星者护腿",
    "s": "Legs, Mail Armor",
    "q": 4,
    "id": "34168",
    "sc": "逐星者护腿",
    "i": "inv_pants_mail_16"
  },
  "34167": {
    "t": "Legplates of the Holy Juggernaut",
    "tc": "神圣信仰腿铠",
    "s": "Legs, Plate Armor",
    "q": 4,
    "id": "34167",
    "sc": "神圣信仰腿铠",
    "i": "inv_pants_plate_22"
  },
  "34382": {
    "t": "Judicator's Legguards",
    "tc": "仲裁者护腿",
    "s": "Legs, Plate Armor",
    "q": 4,
    "id": "34382",
    "sc": "仲裁者护腿",
    "i": "inv_pants_plate_19"
  },
  "34166": {
    "t": "Band of Lucent Beams",
    "tc": "光棱指环",
    "s": "Rings",
    "q": 4,
    "id": "34166",
    "sc": "光棱指环",
    "i": "inv_jewelry_ring_57"
  },
  "34165": {
    "t": "Fang of Kalecgos",
    "tc": "卡雷苟斯之牙",
    "s": "One-Hand, Dagger",
    "q": 4,
    "id": "34165",
    "sc": "卡雷苟斯之牙",
    "i": "inv_weapon_shortblade_74"
  },
  "34164": {
    "t": "Dragonscale-Encrusted Longblade",
    "tc": "龙鳞",
    "s": "One-Hand, Sword",
    "q": 4,
    "id": "34164",
    "sc": "龙鳞",
    "i": "inv_sword_115"
  },
  "34848": {
    "t": "Bracers of the Forgotten Conqueror",
    "tc": "遗忘征服者的护腕",
    "s": "Armor Tokens",
    "q": 4,
    "id": "34848",
    "sc": "遗忘征服者的护腕",
    "i": "inv_bracer_16"
  },
  "34851": {
    "t": "Bracers of the Forgotten Protector",
    "tc": "遗忘保卫者的护腕",
    "s": "Armor Tokens",
    "q": 4,
    "id": "34851",
    "sc": "遗忘保卫者的护腕",
    "i": "inv_bracer_17"
  },
  "34852": {
    "t": "Bracers of the Forgotten Vanquisher",
    "tc": "遗忘胜利者的护腕",
    "s": "Armor Tokens",
    "q": 4,
    "id": "34852",
    "sc": "遗忘胜利者的护腕",
    "i": "inv_bracer_15"
  },
  "34181": {
    "t": "Leggings of Calamity",
    "tc": "灾难护腿",
    "s": "Legs, Cloth Armor",
    "q": 4,
    "id": "34181",
    "sc": "灾难护腿",
    "i": "inv_pants_cloth_05"
  },
  "34180": {
    "t": "Felfury Legplates",
    "tc": "魔怒腿铠",
    "s": "Legs, Plate Armor",
    "q": 4,
    "id": "34180",
    "sc": "魔怒腿铠",
    "i": "inv_pants_plate_24"
  },
  "34381": {
    "t": "Felstrength Legplates",
    "tc": "魔能力量腿铠",
    "s": "Legs, Plate Armor",
    "q": 4,
    "id": "34381",
    "sc": "魔能力量腿铠",
    "i": "inv_pants_plate_21"
  },
  "34178": {
    "t": "Collar of the Pit Lord",
    "tc": "深渊领主颈圈",
    "s": "Amulets",
    "q": 4,
    "id": "34178",
    "sc": "深渊领主颈圈",
    "i": "inv_jewelry_necklace_47"
  },
  "34177": {
    "t": "Clutch of Demise",
    "tc": "终结锁链",
    "s": "Amulets",
    "q": 4,
    "id": "34177",
    "sc": "终结锁链",
    "i": "inv_jewelry_necklace_43"
  },
  "34176": {
    "t": "Reign of Misery",
    "tc": "哀难",
    "s": "Main Hand, Mace",
    "q": 4,
    "id": "34176",
    "sc": "哀难",
    "i": "inv_mace_73"
  },
  "34179": {
    "t": "Heart of the Pit",
    "tc": "深渊之心",
    "s": "Off-hand Frills",
    "q": 4,
    "id": "34179",
    "sc": "深渊之心",
    "i": "inv_offhand_sunwell_d_02"
  },
  "34853": {
    "t": "Belt of the Forgotten Conqueror",
    "tc": "遗忘征服者的腰带",
    "s": "Armor Tokens",
    "q": 4,
    "id": "34853",
    "sc": "遗忘征服者的腰带",
    "i": "inv_belt_31"
  },
  "34854": {
    "t": "Belt of the Forgotten Protector",
    "tc": "遗忘保卫者的腰带",
    "s": "Armor Tokens",
    "q": 4,
    "id": "34854",
    "sc": "遗忘保卫者的腰带",
    "i": "inv_belt_33"
  },
  "34855": {
    "t": "Belt of the Forgotten Vanquisher",
    "tc": "遗忘胜利者的腰带",
    "s": "Armor Tokens",
    "q": 4,
    "id": "34855",
    "sc": "遗忘胜利者的腰带",
    "i": "inv_belt_27"
  },
  "34352": {
    "t": "Borderland Fortress Grips",
    "tc": "堡垒手套",
    "s": "Hands, Plate Armor",
    "q": 4,
    "id": "34352",
    "sc": "堡垒手套",
    "i": "inv_gauntlets_68"
  },
  "34188": {
    "t": "Leggings of the Immortal Night",
    "tc": "永恒长夜护腿",
    "s": "Legs, Leather Armor",
    "q": 4,
    "id": "34188",
    "sc": "永恒长夜护腿",
    "i": "inv_pants_leather_27"
  },
  "34385": {
    "t": "Leggings of the Immortal Beast",
    "tc": "永生野兽护腿",
    "s": "Legs, Leather Armor",
    "q": 4,
    "id": "34385",
    "sc": "永生野兽护腿",
    "i": "inv_pants_leather_23"
  },
  "34186": {
    "t": "Chain Links of the Tumultuous Storm",
    "tc": "雷暴锁链护腿",
    "s": "Legs, Mail Armor",
    "q": 4,
    "id": "34186",
    "sc": "雷暴锁链护腿",
    "i": "inv_pants_mail_15"
  },
  "34383": {
    "t": "Kilt of Spiritual Reconstruction",
    "tc": "灵魂重铸褶裙",
    "s": "Legs, Mail Armor",
    "q": 4,
    "id": "34383",
    "sc": "灵魂重铸褶裙",
    "i": "inv_pants_mail_15"
  },
  "34184": {
    "t": "Brooch of the Highborne",
    "tc": "上层精灵的胸针",
    "s": "Amulets",
    "q": 4,
    "id": "34184",
    "sc": "上层精灵的胸针",
    "i": "inv_jewelry_necklace_44"
  },
  "34185": {
    "t": "Sword Breaker's Bulwark",
    "tc": "折戟壁垒",
    "s": "Off Hand, Shields",
    "q": 4,
    "id": "34185",
    "sc": "折戟壁垒",
    "i": "inv_shield_53"
  },
  "34182": {
    "t": "Grand Magister's Staff of Torrents",
    "tc": "大魔导师的洪流法杖",
    "s": "Two-Hand, Staff",
    "q": 4,
    "id": "34182",
    "sc": "大魔导师的洪流法杖",
    "i": "inv_staff_74"
  },
  "34856": {
    "t": "Boots of the Forgotten Conqueror",
    "tc": "遗忘征服者的长靴",
    "s": "Armor Tokens",
    "q": 4,
    "id": "34856",
    "sc": "遗忘征服者的长靴",
    "i": "inv_boots_plate_06"
  },
  "34857": {
    "t": "Boots of the Forgotten Protector",
    "tc": "遗忘保卫者的长靴",
    "s": "Armor Tokens",
    "q": 4,
    "id": "34857",
    "sc": "遗忘保卫者的长靴",
    "i": "inv_boots_plate_04"
  },
  "34858": {
    "t": "Boots of the Forgotten Vanquisher",
    "tc": "遗忘胜利者的长靴",
    "s": "Armor Tokens",
    "q": 4,
    "id": "34858",
    "sc": "遗忘胜利者的长靴",
    "i": "inv_boots_plate_07"
  },
  "34205": {
    "t": "Shroud of Redeemed Souls",
    "tc": "救赎灵魂披风",
    "s": "Cloaks",
    "q": 4,
    "id": "34205",
    "sc": "救赎灵魂披风",
    "i": "inv_misc_cape_06"
  },
  "34190": {
    "t": "Crimson Paragon's Cover",
    "tc": "血色珍珠披风",
    "s": "Cloaks",
    "q": 4,
    "id": "34190",
    "sc": "血色珍珠披风",
    "i": "inv_misc_cape_18"
  },
  "34210": {
    "t": "Amice of the Convoker",
    "tc": "唤魔师护肩",
    "s": "Shoulder, Cloth Armor",
    "q": 4,
    "id": "34210",
    "sc": "唤魔师护肩",
    "i": "inv_shoulder_96"
  },
  "34202": {
    "t": "Shawl of Wonderment",
    "tc": "奇迹披肩",
    "s": "Shoulder, Cloth Armor",
    "q": 4,
    "id": "34202",
    "sc": "奇迹披肩",
    "i": "inv_shoulder_96"
  },
  "34393": {
    "t": "Shoulderpads of Knowledge's Pursuit",
    "tc": "求知护肩",
    "s": "Shoulder, Cloth Armor",
    "q": 4,
    "id": "34393",
    "sc": "求知护肩",
    "i": "inv_shoulder_96"
  },
  "34209": {
    "t": "Spaulders of Reclamation",
    "tc": "拓荒护肩",
    "s": "Shoulder, Leather Armor",
    "q": 4,
    "id": "34209",
    "sc": "拓荒护肩",
    "i": "inv_shoulder_88"
  },
  "34391": {
    "t": "Spaulders of Devastation",
    "tc": "荒林护肩",
    "s": "Shoulder, Leather Armor",
    "q": 4,
    "id": "34391",
    "sc": "荒林护肩",
    "i": "inv_shoulder_88"
  },
  "34195": {
    "t": "Shoulderpads of Vehemence",
    "tc": "盛怒护肩",
    "s": "Shoulder, Leather Armor",
    "q": 4,
    "id": "34195",
    "sc": "盛怒护肩",
    "i": "inv_shoulder_88"
  },
  "34392": {
    "t": "Demontooth Shoulderpads",
    "tc": "魔牙护肩",
    "s": "Shoulder, Leather Armor",
    "q": 4,
    "id": "34392",
    "sc": "魔牙护肩",
    "i": "inv_shoulder_88"
  },
  "34194": {
    "t": "Mantle of the Golden Forest",
    "tc": "金色森林护肩",
    "s": "Shoulder, Mail Armor",
    "q": 4,
    "id": "34194",
    "sc": "金色森林护肩",
    "i": "inv_shoulder_90"
  },
  "34208": {
    "t": "Equilibrium Epaulets",
    "tc": "均衡肩饰",
    "s": "Shoulder, Mail Armor",
    "q": 4,
    "id": "34208",
    "sc": "均衡肩饰",
    "i": "inv_shoulder_90"
  },
  "34390": {
    "t": "Erupting Epaulets",
    "tc": "爆发护肩",
    "s": "Shoulder, Mail Armor",
    "q": 4,
    "id": "34390",
    "sc": "爆发护肩",
    "i": "inv_shoulder_90"
  },
  "34192": {
    "t": "Pauldrons of Perseverance",
    "tc": "坚定肩铠",
    "s": "Shoulder, Plate Armor",
    "q": 4,
    "id": "34192",
    "sc": "坚定肩铠",
    "i": "inv_shoulder_89"
  },
  "34388": {
    "t": "Pauldrons of Berserking",
    "tc": "狂暴肩铠",
    "s": "Shoulder, Plate Armor",
    "q": 4,
    "id": "34388",
    "sc": "狂暴肩铠",
    "i": "inv_shoulder_89"
  },
  "34193": {
    "t": "Spaulders of the Thalassian Savior",
    "tc": "萨拉斯救世主护肩",
    "s": "Shoulder, Plate Armor",
    "q": 4,
    "id": "34193",
    "sc": "萨拉斯救世主护肩",
    "i": "inv_shoulder_89"
  },
  "34389": {
    "t": "Spaulders of the Thalassian Defender",
    "tc": "萨拉斯防御者护肩",
    "s": "Shoulder, Plate Armor",
    "q": 4,
    "id": "34389",
    "sc": "萨拉斯防御者护肩",
    "i": "inv_shoulder_89"
  },
  "35290": {
    "t": "Sin'dorei Pendant of Conquest",
    "tc": "辛多雷征服坠饰",
    "s": "Amulets",
    "q": 4,
    "id": "35290",
    "sc": "辛多雷征服坠饰",
    "i": "inv_jewelry_necklace_36"
  },
  "35291": {
    "t": "Sin'dorei Pendant of Salvation",
    "tc": "辛多雷拯救坠饰",
    "s": "Amulets",
    "q": 4,
    "id": "35291",
    "sc": "辛多雷拯救坠饰",
    "i": "inv_jewelry_necklace_36"
  },
  "35292": {
    "t": "Sin'dorei Pendant of Triumph",
    "tc": "辛多雷胜利坠饰",
    "s": "Amulets",
    "q": 4,
    "id": "35292",
    "sc": "辛多雷胜利坠饰",
    "i": "inv_jewelry_necklace_36"
  },
  "34204": {
    "t": "Amulet of Unfettered Magics",
    "tc": "自由魔法项链",
    "s": "Amulets",
    "q": 4,
    "id": "34204",
    "sc": "自由魔法项链",
    "i": "inv_jewelry_necklace_40"
  },
  "34189": {
    "t": "Band of Ruinous Delight",
    "tc": "灾难指环",
    "s": "Rings",
    "q": 4,
    "id": "34189",
    "sc": "灾难指环",
    "i": "inv_jewelry_ring_36"
  },
  "34197": {
    "t": "Shiv of Exsanguination",
    "tc": "放血剃刀",
    "s": "One-Hand, Dagger",
    "q": 4,
    "id": "34197",
    "sc": "放血剃刀",
    "i": "inv_weapon_shortblade_75"
  },
  "34199": {
    "t": "Archon's Gavel",
    "tc": "执政官之槌",
    "s": "Main Hand, Mace",
    "q": 4,
    "id": "34199",
    "sc": "执政官之槌",
    "i": "inv_mace_71"
  },
  "34203": {
    "t": "Grip of Mannoroth",
    "tc": "玛诺洛斯之握",
    "s": "Off Hand, Fist Weapon",
    "q": 4,
    "id": "34203",
    "sc": "玛诺洛斯之握",
    "i": "inv_weapon_hand_15"
  },
  "34198": {
    "t": "Stanchion of Primal Instinct",
    "tc": "远古本能之柱",
    "s": "Two-Hand, Staff",
    "q": 4,
    "id": "34198",
    "sc": "远古本能之柱",
    "i": "inv_staff_12"
  },
  "34196": {
    "t": "Golden Bow of Quel'Thalas",
    "tc": "奎尔萨拉斯金弓",
    "s": "Ranged, Bow",
    "q": 4,
    "id": "34196",
    "sc": "奎尔萨拉斯金弓",
    "i": "inv_weapon_bow_38"
  },
  "34206": {
    "t": "Book of Highborne Hymns",
    "tc": "上层精灵的赞美诗",
    "s": "Off-hand Frills",
    "q": 4,
    "id": "34206",
    "sc": "上层精灵的赞美诗",
    "i": "inv_offhand_sunwell_d_01"
  },
  "34232": {
    "t": "Fel Conquerer Raiments",
    "tc": "魔能征服者外衣",
    "s": "Chest, Cloth Armor",
    "q": 4,
    "id": "34232",
    "sc": "魔能征服者外衣",
    "i": "inv_chest_cloth_51"
  },
  "34233": {
    "t": "Robes of Faltered Light",
    "tc": "幻化光芒长袍",
    "s": "Chest, Cloth Armor",
    "q": 4,
    "id": "34233",
    "sc": "幻化光芒长袍",
    "i": "inv_chest_cloth_32"
  },
  "34399": {
    "t": "Robes of Ghostly Hatred",
    "tc": "幽怨之袍",
    "s": "Chest, Cloth Armor",
    "q": 4,
    "id": "34399",
    "sc": "幽怨之袍",
    "i": "inv_chest_cloth_25"
  },
  "34212": {
    "t": "Sunglow Vest",
    "tc": "阳光外衣",
    "s": "Chest, Leather Armor",
    "q": 4,
    "id": "34212",
    "sc": "阳光外衣",
    "i": "inv_chest_leather_12"
  },
  "34398": {
    "t": "Utopian Tunic of Elune",
    "tc": "艾露恩的幻想外套",
    "s": "Chest, Leather Armor",
    "q": 4,
    "id": "34398",
    "sc": "艾露恩的幻想外套",
    "i": "inv_chest_leather_14"
  },
  "34211": {
    "t": "Harness of Carnal Instinct",
    "tc": "野兽本能外套",
    "s": "Chest, Leather Armor",
    "q": 4,
    "id": "34211",
    "sc": "野兽本能外套",
    "i": "inv_chest_leather_14"
  },
  "34397": {
    "t": "Bladed Chaos Tunic",
    "tc": "混沌刀锋外套",
    "s": "Chest, Leather Armor",
    "q": 4,
    "id": "34397",
    "sc": "混沌刀锋外套",
    "i": "inv_chest_leather_16"
  },
  "34234": {
    "t": "Shadowed Gauntlets of Paroxysm",
    "tc": "暗影爆发护手",
    "s": "Hands, Leather Armor",
    "q": 4,
    "id": "34234",
    "sc": "暗影爆发护手",
    "i": "inv_gauntlets_28"
  },
  "34408": {
    "t": "Gloves of the Forest Drifter",
    "tc": "森林流浪者手套",
    "s": "Hands, Leather Armor",
    "q": 4,
    "id": "34408",
    "sc": "森林流浪者手套",
    "i": "inv_gauntlets_51"
  },
  "34229": {
    "t": "Garments of Serene Shores",
    "tc": "静谧海岸外衣",
    "s": "Chest, Mail Armor",
    "q": 4,
    "id": "34229",
    "sc": "静谧海岸外衣",
    "i": "inv_chest_chain_11"
  },
  "34396": {
    "t": "Garments of Crashing Shores",
    "tc": "惊涛胸甲",
    "s": "Chest, Mail Armor",
    "q": 4,
    "id": "34396",
    "sc": "惊涛胸甲",
    "i": "inv_chest_chain_11"
  },
  "34228": {
    "t": "Vicious Hawkstrider Hauberk",
    "tc": "邪恶陆行鸟胸甲",
    "s": "Chest, Mail Armor",
    "q": 4,
    "id": "34228",
    "sc": "邪恶陆行鸟胸甲",
    "i": "inv_chest_chain_17"
  },
  "34215": {
    "t": "Warharness of Reckless Fury",
    "tc": "鲁莽怒火战甲",
    "s": "Chest, Plate Armor",
    "q": 4,
    "id": "34215",
    "sc": "鲁莽怒火战甲",
    "i": "inv_chest_plate20"
  },
  "34394": {
    "t": "Breastplate of Agony's Aversion",
    "tc": "憎恨胸铠",
    "s": "Chest, Plate Armor",
    "q": 4,
    "id": "34394",
    "sc": "憎恨胸铠",
    "i": "inv_chest_plate20"
  },
  "34240": {
    "t": "Gauntlets of the Soothed Soul",
    "tc": "宁静灵魂护手",
    "s": "Hands, Plate Armor",
    "q": 4,
    "id": "34240",
    "sc": "宁静灵魂护手",
    "i": "inv_gauntlets_20"
  },
  "34216": {
    "t": "Heroic Judicator's Chestguard",
    "tc": "英勇仲裁者胸甲",
    "s": "Chest, Plate Armor",
    "q": 4,
    "id": "34216",
    "sc": "英勇仲裁者胸甲",
    "i": "inv_chest_plate18"
  },
  "34395": {
    "t": "Noble Judicator's Chestguard",
    "tc": "尊贵仲裁者胸甲",
    "s": "Chest, Plate Armor",
    "q": 4,
    "id": "34395",
    "sc": "尊贵仲裁者胸甲",
    "i": "inv_chest_plate18"
  },
  "34213": {
    "t": "Ring of Hardened Resolve",
    "tc": "坚定决心指环",
    "s": "Rings",
    "q": 4,
    "id": "34213",
    "sc": "坚定决心指环",
    "i": "inv_jewelry_ring_72"
  },
  "34230": {
    "t": "Ring of Omnipotence",
    "tc": "全能之戒",
    "s": "Rings",
    "q": 4,
    "id": "34230",
    "sc": "全能之戒",
    "i": "inv_jewelry_ring_68"
  },
  "35282": {
    "t": "Sin'dorei Band of Dominance",
    "tc": "辛多雷统御指环",
    "s": "Rings",
    "q": 4,
    "id": "35282",
    "sc": "辛多雷统御指环",
    "i": "inv_jewelry_ring_60"
  },
  "35283": {
    "t": "Sin'dorei Band of Salvation",
    "tc": "辛多雷拯救指环",
    "s": "Rings",
    "q": 4,
    "id": "35283",
    "sc": "辛多雷拯救指环",
    "i": "inv_jewelry_ring_60"
  },
  "35284": {
    "t": "Sin'dorei Band of Triumph",
    "tc": "辛多雷胜利指环",
    "s": "Rings",
    "q": 4,
    "id": "35284",
    "sc": "辛多雷胜利指环",
    "i": "inv_jewelry_ring_60"
  },
  "34427": {
    "t": "Blackened Naaru Sliver",
    "tc": "灰暗的纳鲁薄片",
    "s": "Trinkets",
    "q": 4,
    "id": "34427",
    "sc": "灰暗的纳鲁薄片",
    "i": "inv_jewelry_talisman_16"
  },
  "34430": {
    "t": "Glimmering Naaru Sliver",
    "tc": "闪光的纳鲁薄片",
    "s": "Trinkets",
    "q": 4,
    "id": "34430",
    "sc": "闪光的纳鲁薄片",
    "i": "inv_jewelry_talisman_17"
  },
  "34429": {
    "t": "Shifting Naaru Sliver",
    "tc": "变幻的纳鲁薄片",
    "s": "Trinkets",
    "q": 4,
    "id": "34429",
    "sc": "变幻的纳鲁薄片",
    "i": "inv_jewelry_talisman_15"
  },
  "34428": {
    "t": "Steely Naaru Sliver",
    "tc": "坚硬的纳鲁薄片",
    "s": "Trinkets",
    "q": 4,
    "id": "34428",
    "sc": "坚硬的纳鲁薄片",
    "i": "inv_jewelry_talisman_18"
  },
  "34214": {
    "t": "Muramasa",
    "tc": "村正",
    "s": "One-Hand, Sword",
    "q": 4,
    "id": "34214",
    "sc": "村正",
    "i": "inv_sword_114"
  },
  "34231": {
    "t": "Aegis of Angelic Fortune",
    "tc": "天使命运之盾",
    "s": "Off Hand, Shields",
    "q": 4,
    "id": "34231",
    "sc": "天使命运之盾",
    "i": "inv_shield_52"
  },
  "34241": {
    "t": "Cloak of Unforgivable Sin",
    "tc": "原罪披风",
    "s": "Cloaks",
    "q": 4,
    "id": "34241",
    "sc": "原罪披风",
    "i": "inv_misc_cape_naxxramas_02"
  },
  "34242": {
    "t": "Tattered Cape of Antonidas",
    "tc": "安东尼达斯的破碎斗篷",
    "s": "Cloaks",
    "q": 4,
    "id": "34242",
    "sc": "安东尼达斯的破碎斗篷",
    "i": "inv_misc_cape_20"
  },
  "34339": {
    "t": "Cowl of Light's Purity",
    "tc": "纯净圣光兜帽",
    "s": "Head, Cloth Armor",
    "q": 4,
    "id": "34339",
    "sc": "纯净圣光兜帽",
    "i": "inv_helmet_132"
  },
  "34405": {
    "t": "Helm of Arcane Purity",
    "tc": "奥术纯净头盔",
    "s": "Head, Cloth Armor",
    "q": 4,
    "id": "34405",
    "sc": "奥术纯净头盔",
    "i": "inv_helmet_132"
  },
  "34340": {
    "t": "Dark Conjuror's Collar",
    "tc": "黑暗咒术之环",
    "s": "Head, Cloth Armor",
    "q": 4,
    "id": "34340",
    "sc": "黑暗咒术之环",
    "i": "inv_helmet_132"
  },
  "34342": {
    "t": "Handguards of the Dawn",
    "tc": "黎明护手",
    "s": "Hands, Cloth Armor",
    "q": 4,
    "id": "34342",
    "sc": "黎明护手",
    "i": "inv_gauntlets_16"
  },
  "34406": {
    "t": "Gloves of Tyri's Power",
    "tc": "泰莉的强能手套",
    "s": "Hands, Cloth Armor",
    "q": 4,
    "id": "34406",
    "sc": "泰莉的强能手套",
    "i": "inv_gauntlets_16"
  },
  "34344": {
    "t": "Handguards of Defiled Worlds",
    "tc": "诸界亵渎护手",
    "s": "Hands, Cloth Armor",
    "q": 4,
    "id": "34344",
    "sc": "诸界亵渎护手",
    "i": "inv_gauntlets_15"
  },
  "34244": {
    "t": "Duplicitous Guise",
    "tc": "狡诈之盔",
    "s": "Head, Leather Armor",
    "q": 4,
    "id": "34244",
    "sc": "狡诈之盔",
    "i": "inv_helmet_126"
  },
  "34404": {
    "t": "Mask of the Fury Hunter",
    "tc": "愤怒猎手面具",
    "s": "Head, Leather Armor",
    "q": 4,
    "id": "34404",
    "sc": "愤怒猎手面具",
    "i": "inv_helmet_126"
  },
  "34245": {
    "t": "Cover of Ursol the Wise",
    "tc": "智者乌索尔之帽",
    "s": "Head, Leather Armor",
    "q": 4,
    "id": "34245",
    "sc": "智者乌索尔之帽",
    "i": "inv_helmet_126"
  },
  "34403": {
    "t": "Cover of Ursoc the Mighty",
    "tc": "巨熊乌索克之帽",
    "s": "Head, Leather Armor",
    "q": 4,
    "id": "34403",
    "sc": "巨熊乌索克之帽",
    "i": "inv_helmet_126"
  },
  "34333": {
    "t": "Coif of Alleria",
    "tc": "奥蕾莉亚的头盔",
    "s": "Head, Mail Armor",
    "q": 4,
    "id": "34333",
    "sc": "奥蕾莉亚的头盔",
    "i": "inv_helmet128"
  },
  "34332": {
    "t": "Cowl of Gul'dan",
    "tc": "古尔丹的兜帽",
    "s": "Head, Mail Armor",
    "q": 4,
    "id": "34332",
    "sc": "古尔丹的兜帽",
    "i": "inv_helmet128"
  },
  "34402": {
    "t": "Shroud of Chieftain Ner'zhul",
    "tc": "耐奥祖酋长的兜帽",
    "s": "Head, Mail Armor",
    "q": 4,
    "id": "34402",
    "sc": "耐奥祖酋长的兜帽",
    "i": "inv_helmet128"
  },
  "34343": {
    "t": "Thalassian Ranger Gauntlets",
    "tc": "萨拉斯游侠护手",
    "s": "Hands, Mail Armor",
    "q": 4,
    "id": "34343",
    "sc": "萨拉斯游侠护手",
    "i": "inv_gauntlets_51"
  },
  "34243": {
    "t": "Helm of Burning Righteousness",
    "tc": "燃烧的正义",
    "s": "Head, Plate Armor",
    "q": 4,
    "id": "34243",
    "sc": "燃烧的正义",
    "i": "inv_helmet_25"
  },
  "34401": {
    "t": "Helm of Uther's Resolve",
    "tc": "乌瑟尔的决心之盔",
    "s": "Head, Plate Armor",
    "q": 4,
    "id": "34401",
    "sc": "乌瑟尔的决心之盔",
    "i": "inv_helmet_25"
  },
  "34345": {
    "t": "Crown of Anasterian",
    "tc": "安纳斯特里亚的王冠",
    "s": "Head, Plate Armor",
    "q": 4,
    "id": "34345",
    "sc": "安纳斯特里亚的王冠",
    "i": "inv_helmet_92"
  },
  "34400": {
    "t": "Crown of Dath'Remar",
    "tc": "达斯雷玛之冠",
    "s": "Head, Plate Armor",
    "q": 4,
    "id": "34400",
    "sc": "达斯雷玛之冠",
    "i": "inv_helmet_08"
  },
  "34341": {
    "t": "Borderland Paingrips",
    "tc": "边疆手套",
    "s": "Hands, Plate Armor",
    "q": 4,
    "id": "34341",
    "sc": "边疆手套",
    "i": "inv_gauntlets_65"
  },
  "34334": {
    "t": "Thori'dal, the Stars' Fury",
    "tc": "索利达尔，群星之怒",
    "s": "Ranged, Bow",
    "q": 5,
    "id": "34334",
    "sc": "索利达尔，群星之怒",
    "i": "inv_weapon_bow_39"
  },
  "34329": {
    "t": "Crux of the Apocalypse",
    "tc": "天启",
    "s": "One-Hand, Dagger",
    "q": 4,
    "id": "34329",
    "sc": "天启",
    "i": "inv_weapon_shortblade_75"
  },
  "34247": {
    "t": "Apolyon, the Soul-Render",
    "tc": "奥伯莱恩，裂日之剑",
    "s": "Two-Hand, Sword",
    "q": 4,
    "id": "34247",
    "sc": "奥伯莱恩，裂日之剑",
    "i": "inv_sword_116"
  },
  "34335": {
    "t": "Hammer of Sanctification",
    "tc": "圣灵之锤",
    "s": "Main Hand, Mace",
    "q": 4,
    "id": "34335",
    "sc": "圣灵之锤",
    "i": "inv_mace_72"
  },
  "34331": {
    "t": "Hand of the Deceiver",
    "tc": "欺诈者之手",
    "s": "Main Hand, Fist Weapon",
    "q": 4,
    "id": "34331",
    "sc": "欺诈者之手",
    "i": "inv_weapon_hand_15"
  },
  "34336": {
    "t": "Sunflare",
    "tc": "日灸",
    "s": "Main Hand, Dagger",
    "q": 4,
    "id": "34336",
    "sc": "日灸",
    "i": "inv_weapon_shortblade_73"
  },
  "34337": {
    "t": "Golden Staff of the Sin'dorei",
    "tc": "辛多雷金杖",
    "s": "Two-Hand, Staff",
    "q": 4,
    "id": "34337",
    "sc": "辛多雷金杖",
    "i": "inv_staff_73"
  },
  "34351": {
    "t": "Tranquil Majesty Wraps",
    "tc": "稳固王权护手",
    "s": "Hands, Leather Armor",
    "q": 4,
    "id": "34351",
    "sc": "稳固王权护手",
    "i": "inv_gauntlets_48"
  },
  "34407": {
    "t": "Tranquil Moonlight Wraps",
    "tc": "宁静月光护手",
    "s": "Hands, Leather Armor",
    "q": 4,
    "id": "34407",
    "sc": "宁静月光护手",
    "i": "inv_gauntlets_51"
  },
  "34350": {
    "t": "Gauntlets of the Ancient Shadowmoon",
    "tc": "上古影月护手",
    "s": "Hands, Mail Armor",
    "q": 4,
    "id": "34350",
    "sc": "上古影月护手",
    "i": "inv_gauntlets_41"
  },
  "34409": {
    "t": "Gauntlets of the Ancient Frostwolf",
    "tc": "上古霜狼护手",
    "s": "Hands, Mail Armor",
    "q": 4,
    "id": "34409",
    "sc": "上古霜狼护手",
    "i": "inv_gauntlets_52"
  },
  "35733": {
    "t": "Ring of Harmonic Beauty",
    "tc": "谐和之戒",
    "s": "Rings",
    "q": 4,
    "id": "35733",
    "sc": "谐和之戒",
    "i": "inv_jewelry_ring_57"
  },
  "34183": {
    "t": "Shivering Felspine",
    "tc": "魔脊之枪",
    "s": "Two-Hand, Polearm",
    "q": 4,
    "id": "34183",
    "sc": "魔脊之枪",
    "i": "inv_weapon_halberd_20"
  },
  "34346": {
    "t": "Mounting Vengeance",
    "tc": "复仇",
    "s": "Off Hand, Fist Weapon",
    "q": 4,
    "id": "34346",
    "sc": "复仇",
    "i": "inv_weapon_hand_13"
  },
  "34348": {
    "t": "Wand of Cleansing Light",
    "tc": "净化光芒魔杖",
    "s": "Ranged, Wand",
    "q": 4,
    "id": "34348",
    "sc": "净化光芒魔杖",
    "i": "inv_wand_24"
  },
  "34347": {
    "t": "Wand of the Demonsoul",
    "tc": "魔魂手杖",
    "s": "Ranged, Wand",
    "q": 4,
    "id": "34347",
    "sc": "魔魂手杖",
    "i": "inv_wand_25"
  },
  "34349": {
    "t": "Blade of Life's Inevitability",
    "tc": "死亡飞刀",
    "s": "Thrown, Thrown",
    "q": 4,
    "id": "34349",
    "sc": "死亡飞刀",
    "i": "inv_throwingknife_07"
  }
}
