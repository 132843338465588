import { SVGIcon } from "./SVGIcon";

import { faXmark } from "@fortawesome/free-solid-svg-icons";

import { Modal as BaseModal } from "@restart/ui";
import classNames from "classnames/bind";

export const Modal = (props) => {
  const {
    children,
    title,
    isOpen,
    toggleOpen,
    showCloseBtn = true,
    renderBackdrop = () => <div class="modal-background" />,
    extraButtons = [],
    style,
  } = props;

  const renderButtons = extraButtons.map((btn, i) => {
    return (
      <button
        onClick={btn.callback}
        class={classNames("button", "is-text", btn.cls)}
      >
        {btn.title}
      </button>
    );
  });

  return (
    <BaseModal
      show={isOpen}
      onHide={toggleOpen}
      renderBackdrop={renderBackdrop}
      class="modal"
      style={style}
    >
      <header class="modal-head">
        <p class="title">{title}</p>
        <span class="icon" onClick={toggleOpen}>
          <SVGIcon cls="icon" faSVG={faXmark} />
        </span>
      </header>
      <section class="modal-body">{children}</section>
      <footer class="modal-foot">
        <div class="foot-buttons">
          {showCloseBtn ? (
            <button class="button is-text" onClick={toggleOpen}>
              Close
            </button>
          ) : null}
          {renderButtons}
        </div>
      </footer>
    </BaseModal>
  );
};
