import { getTemplate, updateRaidData } from "~src/db/raidDB";
import { nanoid } from "nanoid";

export const createRaidTemplate = () => {};

export const createRaidFromTemplate = async (title, type, templateID) => {
  console.log("create new", title, type, templateID);
  if (templateID == "default") {
    templateID = "";
  }
  const newRaidID = nanoid(8);
  if (templateID) {
    const template = await getTemplate(templateID);
    delete template.templateID;
    delete template.templateName;
    delete template.templateDate;
    template.config.title = title;
    template.config.raidDate = Date.now();
    template.instanceID = newRaidID;

    await updateRaidData(template);
  } else {
    // default
    await updateRaidData({
      instanceID: newRaidID,
      raidType: type,
      title: title,
      mode: "default",
    });
  }

  return newRaidID;
};
