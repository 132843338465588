import { SingleRecord } from "./SingleRecord";
import { SingleRecordExtra } from "./SingleRecordExtra";
import { SVGIcon } from "./SVGIcon";
import {
  RaidContext,
  getRaidItemContext,
  RAID,
} from "../collection/RaidContext";
import {
  ITEM_TYPE,
  EXTRA_RECORD_TYPE,
  BONUS_TYPE,
  BONUS_TYPE_SVG,
  BONUS_CALC_TYPE,
  BONUS_SPLIT_TYPE,
  RAID_NAME,
  WOW_COLOR
} from "../const";

import {
  calcRecordTotal,
  calcExtraRecordTotal,
  calcBonusRule,
  formatRuleDesc,
} from "../helper/raidPaymentCalc";

import { route } from "preact-router";
import { useSelector, shallowEqual, useDispatch, Provider } from "react-redux";

import { Modal } from "./Modal";
import { toast } from "react-toastify";

import {
  faPlus,
  faFileImport,
  faGear,
  faGrid2Plus,
  faUser,
  faCoins,
  faUserGroup,
  faShareNodes,
  faLoader,
  faCheck,
  faSquareRss,
  faPuzzlePiece,
  faMemo,
  faBooksMedical,
  faSkull,
  faHouse,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { useState, useRef, useContext, useEffect, useMemo } from "preact/hooks";
import classNames from "classnames";
import {exportAsPng} from "../helper/exportHelper"
import * as dayjs from "dayjs";

const ModalImportItem = (props) => {
  const [ignoreItem, setIgnoreItem] = useState(true);
  const [importText, setImportText] = useState(false);
  const textRef = useRef(null);
  const { dispatch, raidItemList, toggleOpen } = props;

  const doImport = () => {
    const importString = textRef.current.value.trim();
    if (!importString) {
      toast.warn("Empty Import String");
      return;
    }

    // split
    const item_list = importString.split("\n");
    let items = [];
    let ignoreCount = 0;

    for (const item of item_list) {
      if (!item) continue;

      let i = item.split("|");
      if (i[1] != "CREDIT") continue;

      let record = {
        id: i[0],
        count: i[3],
        value: i[2].slice(2),
      };
      record["type"] = i[2][0] == "i" ? ITEM_TYPE.ITEM_ID : ITEM_TYPE.STRING;

      // check import options
      switch (record["type"]) {
        case ITEM_TYPE.STRING: {
          if (!importText) {
            continue;
          }
          break;
        }
        case ITEM_TYPE.ITEM_ID: {
          const raidItem = raidItemList[record.value];
          if (!raidItem) {
            if (ignoreItem) {
              ignoreCount++;
              continue;
            }
            // remap item to string.
            record["type"] = ITEM_TYPE.STRING;
            record["value"] = "Unlisted: " + record["value"];
          }
          break;
        }
      }
      items.push(record);
    }

    dispatch({
      type: "raid/record/import",
      payload: items,
    });

    toggleOpen();
    let msg = ["Import Done!"];
    if (ignoreCount > 0) {
      msg.push(`${ignoreCount} records ignored.`);
    }
    toast.info(msg.join("\n"));
  };

  const newprops = {
    ...props,
    title: "Import",
    style: "width: 600px; height: 500px",
    extraButtons: [
      {
        cls: "is-primary",
        title: "Import",
        callback: doImport,
      },
    ],
  };

  return (
    <Modal {...newprops}>
      <textarea
        ref={textRef}
        class="textarea dynamic-size is-fixwidth"
        placeholder="Paste GDKP item string here."
      />
      <div class="field">
        <input
          id="modalImportSW1"
          name="modalImportSW1"
          type="checkbox"
          class="switch"
          checked={ignoreItem}
          onChange={() => {
            setIgnoreItem(!ignoreItem);
          }}
        />
        <label for="modalImportSW1">Ignore Non-listed Items</label>
      </div>
      <div class="field">
        <input
          type="checkbox"
          id="modalImportSW2"
          name="modalImportSW2"
          checked={importText}
          onChange={() => {
            setImportText(!importText);
          }}
          class="switch"
        />
        <label for="modalImportSW2">Import Text String</label>
      </div>
    </Modal>
  );
};

const ModalRaidBonusRow = (props) => {
  const { record_id } = props;
  const record = useSelector((state) => {
    return state.config.bonusList[record_id];
  }, shallowEqual);

  const [selectOpen, setSelectOpen] = useState(false);
  const dispatch = useDispatch();

  const updateBonus = (data) => {
    dispatch({
      type: "raid/config/update",
      payload: { ...data, id: record_id },
    });
  };

  const ref = useRef();
  const toggleSelect = () => {
    const closeMenu = () => {
      setSelectOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    };

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeMenu();
      }
    }

    if (selectOpen) {
      closeMenu();
    } else {
      setSelectOpen(true);
      document.addEventListener("mousedown", handleClickOutside);
    }
  };

  const titleRef = useRef();
  const doTitleUpdate = () => {
    updateBonus({
      recordTitle: titleRef.current?.value || "",
    });
    toggleSelect();
  };
  const handleKeyDownTitle = (event) => {
    if (event.key === "Enter") {
      doTitleUpdate();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };

  const blurHandle = (key) => {
    const onBlur = (event) => {
      const target = event.target;
      updateBonus({ [key]: target.value });
    };
    return onBlur;
  };

  return (
    <div class="raid-config-row">
      <span class="icon">
        <SVGIcon cls="icon" faSVG={BONUS_TYPE_SVG[record.recordType]} />
      </span>
      <p class="title" onClick={toggleSelect}>
        {record.recordTitle || "[New]"}
      </p>
      <div class="control with-radio">
        <div class="type-select">
          <label>
            <input
              type="radio"
              name={"bonus-calc-type-" + record_id}
              checked={record.calcType == BONUS_CALC_TYPE.TYPE_FIXED}
              onChange={() =>
                updateBonus({ calcType: BONUS_CALC_TYPE.TYPE_FIXED })
              }
            />
            <div class="box">
              <span>Gold</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              name={"bonus-calc-type-" + record_id}
              checked={record.calcType == BONUS_CALC_TYPE.TYPE_PERCENT}
              onChange={() =>
                updateBonus({ calcType: BONUS_CALC_TYPE.TYPE_PERCENT })
              }
            />
            <div class="box">
              <span>% Pct.</span>
            </div>
          </label>
        </div>
        <input
          // ref={cutRef}
          class="input"
          placeholder=""
          type="number"
          onKeyDown={handleKeyDown}
          value={record.calcValue}
          onBlur={blurHandle("calcValue")}
        />
      </div>
      <div class="control with-radio">
        <div class="type-select">
          <label>
            <input
              type="radio"
              name={"bonus-split-type-" + record_id}
              checked={record.splitType == BONUS_SPLIT_TYPE.TYPE_EACH}
              onChange={() =>
                updateBonus({ splitType: BONUS_SPLIT_TYPE.TYPE_EACH })
              }
            />
            <div class="box">
              <span>EA.</span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              name={"bonus-split-type-" + record_id}
              checked={record.splitType == BONUS_SPLIT_TYPE.TYPE_SPLIT}
              onChange={() =>
                updateBonus({ splitType: BONUS_SPLIT_TYPE.TYPE_SPLIT })
              }
            />
            <div class="box">
              <span>Cuts</span>
            </div>
          </label>
        </div>
        <input
          // ref={cutRef}
          class="input"
          placeholder=""
          type="number"
          onKeyDown={handleKeyDown}
          value={record.splitValue}
          onBlur={blurHandle("splitValue")}
        />
      </div>
      {selectOpen ? (
        <div class="select-overlayer" ref={ref}>
          <div class="icon-selector">
            {Object.entries(BONUS_TYPE_SVG).map(([k, v]) => (
              <label>
                <input
                  type="radio"
                  name="icon-select-type"
                  checked={record.recordType == k}
                  onChange={() => updateBonus({ recordType: k })}
                />
                <div class="box-icon">
                  <SVGIcon cls="icon" faSVG={v} />
                </div>
              </label>
            ))}
          </div>
          <div class="control has-icon-right">
            <input
              ref={titleRef}
              class="input"
              placeholder=""
              onKeyDown={handleKeyDownTitle}
              value={record.recordTitle}
            />
            <span class="icon is-right" onClick={doTitleUpdate}>
              <SVGIcon cls="icon is-green is-hover" faSVG={faCheck} />
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const ModalRaidSetting = (props) => {
  const newprops = {
    ...props,
    title: "Raid Setting",
    style: "width: 600px; height: 500px",
  };
  const dispatch = useDispatch();

  // const cutRef = useRef();
  const splitCut = useSelector((state) => {
    return state.config.splitCut;
  }, shallowEqual);

  const raidTitle = useSelector((state) => {
    return state.config.title;
  }, shallowEqual);

  const bonusList = useSelector((state) => {
    return Object.keys(state.config.bonusList);
  }, shallowEqual);

  const addRecord = () => {
    dispatch({
      type: "raid/config/newBonus",
    });
  };

  const updateConfig = (key) => {
    const doUpdate = (event) => {
      dispatch({
        type: "raid/config/update_config",
        payload: {
          key: key,
          value: event.target?.value,
        },
      });
    };
    return doUpdate;
  };

  const handleKeyDownWrapper = (callback) => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        (callback && callback()) || event.target.blur();
      }
    };
    return handleKeyDown;
  };

  return (
    <Modal {...newprops}>
      <div class="raid-config-general">
        <div class="raid-config-row simple-input">
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faSquareRss} />
          </span>
          <p class="title">Raid Title</p>
          <div class="control">
            <input
              style="width: 20rem"
              class="input"
              placeholder=""
              onKeyDown={handleKeyDownWrapper()}
              onBlur={updateConfig("title")}
              value={raidTitle}
            />
          </div>
        </div>
        <div class="raid-config-row simple-input">
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faUser} />
          </span>
          <p class="title">Total Splits</p>
          <div class="control">
            <input
              class="input"
              placeholder=""
              type="number"
              onKeyDown={handleKeyDownWrapper()}
              onBlur={updateConfig("splitCut")}
              value={splitCut}
            />
          </div>
        </div>
      </div>
      <div class="raid-config-bonus">
        <div class="config-header">
          <span class="title">Bonus List</span>
          <button class="button" onClick={addRecord}>
            <span class="icon">
              <SVGIcon cls="icon" faSVG={faPlus} />
            </span>
          </button>
        </div>
        <div class="config-containter">
          {bonusList.map((record_id) => (
            <ModalRaidBonusRow record_id={record_id} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

const ModalShareTemplate = (props) => {
  const { toggleOpen, dispatch, itemList } = props;
  const [option, setOption] = useState({
    includeDisenchant: false,
    includeExtra: true
  });

  const ref = useRef();
  const raidConfig = useSelector((state) => {
    return {
      raidType: state.raidType,
      raidName: RAID_NAME[state.raidType],
      raidTitle: state.config.title,
      // date: dayjs(state.config.raidDate).format("MMM DD, YYYY")
      date: state.config.raidDate,
    }
  }, shallowEqual);

  const newprops = {
    ...props,
    title: "Export Loot Record",
    style: "width: 720px; height: 500px",
    extraButtons: [
      {
        cls: "is-primary",
        title: "Save as PNG",
        callback: () => {
          let date = dayjs(raidConfig.date).format("MMM DD")
          let title = `${raidConfig.raidName.short} - ${date}`;
          if (raidConfig.raidTitle) {
            title += `(${raidConfig.raidTitle})`
          }
          exportAsPng(ref.current, title)
        },
      },
    ],
  };

  // const raidSplit = useSelector((state) => {, shallowEqual})
  const raidRecords = useSelector((state) => {
    let records = Object.values(state.records.records).map(v => v.data.type == ITEM_TYPE.ITEM_ID ? {item_id: v.data.value, price: v.price, isDisenchant: v.isDisenchant, is_item: true} : {title: v.data.value ,price: v.price, isDisenchant: v.isDisenchant, is_string:true}).filter(r => r);
    let extraRecords = Object.values(state.extraRecords.records).map(v => v.price > 0 ? {title: v.title, type: v.type, price: v.price} : null).filter(r => r);
    // console.log(records, extraRecords)
    return records.concat(extraRecords)
  }, shallowEqual);

  const splitCuts = useSelector((state) => state.config.splitCut);

  const [filteredRecords, setFilteredRecords ] = useState([]);
  const [totalIncoming, setTotalIncoming] = useState(0);
  useEffect(()=>{
    let results = [];
    let total = 0;
    for (const record of raidRecords) {
      if (record.is_item) {
        let isDisenchant = record.isDisenchant || record.price == 0;
        if (isDisenchant && !option.includeDisenchant)
          continue
        let item = itemList[record.item_id]
        results.push({
          i: item.i,
          t: item.t,
          q: WOW_COLOR[item.q],
          tc: item.tc,
          p: record.price,
          cls: {'x-disenchant': isDisenchant}
        });
      }
      else if (record.is_string) {
        let isDisenchant = record.isDisenchant || record.price == 0;
        if (isDisenchant && !option.includeDisenchant)
          continue
        results.push({
          i: "wow_token01",
          t: record.title,
          q: WOW_COLOR[6],
          tc: "",
          p: record.price,
          cls: {'x-disenchant': isDisenchant}
        });
      }else{
        if(!option.includeExtra)
          continue
        let isCredit = (record.type == EXTRA_RECORD_TYPE.CREDIT)
        results.push({
          i: "wow_token01",
          t: record.title,
          q: WOW_COLOR[1],
          tc: isCredit ? "Credit / 收入" : "Expense / 支出",
          p: record.price,
          cls: {'x-extra': true, 'x-credit': isCredit}
        });
      }
      // calc total incoming
      if (!record.isDisenchant) {
        console.log(record.price);
        total += record.price * ((record.type == EXTRA_RECORD_TYPE.EXPENSE) ? -1: 1);
      }

    }
    setFilteredRecords(results);
    setTotalIncoming(total);
  }, [raidRecords, option])

  return (
    <Modal {...newprops}>
      <div class='raid-export-loot'>
        <div class='loot-control-bar'>
          <span
            onClick={()=>{
              setOption({ ...option, includeDisenchant: !option.includeDisenchant })
            }}
            class={classNames("x-icon", "x-icon-disenchant", {"is-select": option.includeDisenchant})}></span>
          <span
            onClick={()=>{
              setOption({ ...option, includeExtra: !option.includeExtra })
            }}
            class={classNames("x-icon", "x-icon-extra", {"is-select": option.includeExtra})}></span>
        </div>
        <div class='loot-body' ref={ref}>
          <header style={`background: url(https://cdn-z3.c70.ca/${raidConfig.raidType.toLowerCase()}.webp)`}>
            <div class="layer">
              <div class='raid-title'>
                <span class='title'>{raidConfig.raidName.full}</span>
                <span class='subtitle'>{raidConfig.raidName.zhcn}</span>
              </div>
              <div class='raid-info'>
                <span class='subtitle'>{dayjs(raidConfig.date).format("MMM DD, YYYY")}</span>
                <span class='subtitle'>{raidConfig.raidTitle}</span>
              </div>
            </div>
          </header>
          <main>
            {
              filteredRecords.map((v) => (
                <div class={classNames("raid-record-row", v.cls)}>
                  <div class="item-wrapper">
                    <div class="media">
                      <div class="media-left">
                        <figure class="image is-32x32">
                          <img src={`https://cros-wow.c70.ca/images/wow/icons/medium/${v.i}.jpg`} alt="" />
                        </figure>
                      </div>
                      <div class="media-content">
                        <p class="title"><a style={`color: ${v.q}`}>{v.t}</a></p>
                        <p class="subtitle">{v.tc}</p></div></div>
                  </div>
                  <div class="price-wrapper"><div class="price-context"><span class="x-icon x-icon-gold"></span><span class="price-text">{v.p}</span></div></div>
                </div>
              )
            )}
          </main>
          <footer>
            <div class="raid-record-row x-summary">
              <div class="item-wrapper">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-32x32">
                      <img src={`https://cdn-z3.c70.ca/icons/inv_misc_coin_01.webp`} alt="" />
                    </figure>
                  </div>
                  <div class="media-content">
                    <p class="title"><a style="">Total Incoming</a></p>
                    <p class="subtitle">总收入</p></div></div>
              </div>
              <div class="price-wrapper"><div class="price-context"><span class="x-icon x-icon-gold"></span><span class="price-text">{totalIncoming}</span></div></div>
            </div>
            <div class="raid-record-row x-summary">
              <div class="item-wrapper">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-32x32">
                      <img src={`https://cdn-z3.c70.ca/icons/inv_misc_coin_01.webp`} alt="" />
                    </figure>
                  </div>
                  <div class="media-content">
                    <p class="title"><a style="">Raid Tax / 税</a></p>
                    <p class="subtitle">10%</p></div></div>
              </div>
              <div class="price-wrapper"><div class="price-context"><span class="x-icon x-icon-gold"></span><span class="price-text">{Math.ceil(totalIncoming * 0.1)}</span></div></div>
            </div>
            <div class="raid-record-row x-summary">
              <div class="item-wrapper">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-32x32">
                      <img src={`https://cdn-z3.c70.ca/icons/inv_misc_coin_01.webp`} alt="" />
                    </figure>
                  </div>
                  <div class="media-content">
                    <p class="title"><a style="">Each / 每人</a></p>
                    <p class="subtitle">Split / 人数 - {splitCuts}</p></div></div>
              </div>
              <div class="price-wrapper"><div class="price-context"><span class="x-icon x-icon-gold"></span><span class="price-text">{Math.floor(Math.floor(totalIncoming * 0.9) / splitCuts)}</span></div></div>
            </div>
          </footer>
        </div>
      </div>
    </Modal>
  );
}

const ModalRaidTemplate = (props) => {
  const { toggleOpen, dispatch } = props;
  const [templateName, setTemplateName] = useState("");
  const [option, setOption] = useState({
    includeRecord: false,
    includeExtra: true,
  });

  const newprops = {
    ...props,
    title: "Save as Template",
    style: "width: 600px; height: 400px",
    extraButtons: [
      {
        cls: "is-primary",
        title: "Save",
        callback: () => {
          //console.log(templateName, option);
          if (!templateName) {
            toast.warn("Template Name cannot be empty.");
            return;
          }
          dispatch({
            type: "__self/templateCreate",
            payload: {
              title: templateName,
              options: option,
            },
          });
          toggleOpen();
        },
      },
    ],
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };

  return (
    <Modal {...newprops}>
      <div class="raid-config-general">
        <div class="raid-config-row simple-input">
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faSquareRss} />
          </span>
          <p class="title">Template Name</p>
          <div class="control">
            <input
              style="width: 20rem"
              class="input"
              placeholder=""
              onKeyDown={handleKeyDown}
              onBlur={(e) => {
                setTemplateName(e.target?.value.trim());
              }}
              value={templateName}
            />
          </div>
        </div>
        <div class="raid-config-row simple-input">
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faMemo} />
          </span>
          <p class="title">Records</p>
          <div class="control">
            <input
              id="sw-record"
              class="checkbox"
              type="checkbox"
              checked={option.includeRecord}
              onChange={() =>
                setOption({ ...option, includeRecord: !option.includeRecord })
              }
            />
            <label for="sw-record" />
          </div>
        </div>
        <div class="raid-config-row simple-input">
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faBooksMedical} />
          </span>
          <p class="title">Extra Records</p>
          <div class="control">
            <input
              id="sw-extra"
              class="checkbox"
              type="checkbox"
              checked={option.includeExtra}
              onChange={() =>
                setOption({ ...option, includeExtra: !option.includeExtra })
              }
            />
            <label for="sw-extra" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const RaidControl = (props) => {
  const dispatch = useDispatch();
  const addRecord = () => {
    dispatch({
      type: "raid/record/new",
    });
  };

  const addExtraRecord = () => {
    dispatch({
      type: "raid/extra_record/new",
    });
  };

  const [importOpen, setImportOpen] = useState(false);
  const toggleImportOpen = () => {
    setImportOpen(!importOpen);
  };

  const [settingOpen, setSettingOpen] = useState(false);
  const toggleSettingOpen = () => {
    setSettingOpen(!settingOpen);
  };

  const [templateOpen, setTemplateOpen] = useState(false);
  const toggleTemplateOpen = () => {
    setTemplateOpen(!templateOpen);
  };

  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = () => {
    setShareOpen(!shareOpen);
  };

  const raidItemList = useContext(RaidContext);
  const raidTitle = useSelector((state) => {
    const title = [RAID_NAME[state.raidType].short];
    if (state.config.title) {
      title.push(state.config.title);
    }
    return title.join(" - ");
  }, shallowEqual);

  return (
    <div class="raid-control">
      <div class="raid-title">
        <button class="button" onClick={() => route("/", true)}>
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faHouse} />
          </span>
        </button>
        <span class="title">{raidTitle}</span>
      </div>
      <button class="button" onClick={toggleImportOpen}>
        <span class="icon">
          <SVGIcon cls="icon" faSVG={faFileImport} />
        </span>
      </button>
      <button class="button" onClick={addRecord}>
        <span class="icon">
          <SVGIcon cls="icon" faSVG={faPlus} />
        </span>
      </button>
      <button class="button" onClick={addExtraRecord}>
        <span class="icon">
          <SVGIcon cls="icon" faSVG={faGrid2Plus} />
        </span>
      </button>
      <button class="button" onCLick={toggleShareOpen}>
        <span class="icon">
          <SVGIcon cls="icon" faSVG={faShareNodes} />
        </span>
      </button>
      <button class="button" onClick={toggleSettingOpen}>
        <span class="icon">
          <SVGIcon cls="icon" faSVG={faGear} />
        </span>
      </button>
      <button
        class="button"
        onClick={() => {
          if (confirm("Remove Record?")) {
            dispatch({ type: "__self/remove" });
            // route("/", true);
          }
        }}
      >
        <span class="icon">
          <SVGIcon cls="icon" faSVG={faTrash} />
        </span>
      </button>
      <button class="button" onClick={toggleTemplateOpen}>
        <span class="icon">
          <SVGIcon cls="icon" faSVG={faPuzzlePiece} />
        </span>
      </button>
      {importOpen && <ModalImportItem
        raidItemList={raidItemList}
        isOpen={importOpen}
        toggleOpen={toggleImportOpen}
        dispatch={dispatch}
      />}
      {settingOpen && <ModalRaidSetting
        isOpen={settingOpen}
        toggleOpen={toggleSettingOpen}
        dispatch={dispatch}
      />}
      {templateOpen && <ModalRaidTemplate
        isOpen={templateOpen}
        toggleOpen={toggleTemplateOpen}
        dispatch={dispatch}
      />}
      {shareOpen && <ModalShareTemplate
        isOpen={shareOpen}
        toggleOpen={toggleShareOpen}
        dispatch={dispatch}
        itemList={raidItemList}
      />}
    </div>
  );
};

const RaidAllReocrds = (props) => {
  const { isLoading } = props;
  const bottomRef = useRef(null);
  const [totalItems, setTotalItems] = useState(-1);
  const allRecords = useSelector((state) => {
    return Object.keys(state.records.records);
  }, shallowEqual);

  // const isLoading = useSelector((state) => state.internalStatus.isLoading, shallowEqual);

  useEffect(() => {
    if (isLoading) {
      setTotalItems(100);
      return;
    }
    let currentLength = allRecords.length;
    // console.log('set ', currentLength, totalItems)
    // 9 items without scroll.
    if (currentLength > 9 && currentLength > totalItems) {
      bottomRef.current.scrollIntoView();
    }
    setTotalItems(currentLength);
  }, [allRecords, isLoading]);

  return (
    <main class="raid-record-main">
      {isLoading ? null : (
        <Fragment>
          <div class="raid-record-header">Loot Records</div>
          {allRecords.length > 0 ? (
            allRecords.map((record_id) => (
              <SingleRecord record_id={record_id} />
            ))
          ) : (
            <div class="raid-record-empty">
              <span>No Record.</span>
            </div>
          )}
          <div ref={bottomRef} />
        </Fragment>
      )}
    </main>
  );
};

const RaidExtraRecords = (props) => {
  const { isLoading } = props;
  const bottomRef = useRef(null);
  const [totalItems, setTotalItems] = useState(-1);
  const allRecords = useSelector((state) => {
    return Object.keys(state.extraRecords.records);
  }, shallowEqual);

  useEffect(() => {
    if (isLoading) {
      setTotalItems(100);
      return;
    }
    let currentLength = allRecords.length;
    if (currentLength > 4 && currentLength > totalItems) {
      bottomRef.current.scrollIntoView();
    }
    setTotalItems(currentLength);
  }, [allRecords, isLoading]);
  // const test = {
  //   recordTitle: "Test", recordType: 1, price: 0, removeThisRecord: ()=>{}
  // }

  return (
    <main class="raid-record-extra">
      <div class="raid-record-header">Other Records</div>
      {allRecords.length > 0 ? (
        allRecords.map((record_id) => (
          <SingleRecordExtra record_id={record_id} />
        ))
      ) : (
        <div class="raid-record-empty">
          <span>No Record.</span>
        </div>
      )}
      <div ref={bottomRef} />
    </main>
  );
};

const Card = (props) => {
  const {
    title,
    icon = faLoader,
    titleClass,
    isDouble = false,
    isRecord = false,
    recordData = {},
    enableCopy = true,
  } = props;
  const [copied, setCopyied] = useState(false);

  const copyHandle = () => {
    if (!enableCopy || copied) {
      return;
    }
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(title);
      setCopyied(true);
      setTimeout(() => {
        setCopyied(false);
      }, 1500);
    } else {
      console.log("Unable to copy!");
    }
  };

  return (
    <div
      class={classNames("card", {
        copiable: enableCopy,
        "is-copied": copied,
        "is-record": isRecord,
        "is-double": isDouble,
      })}
      onClick={copyHandle}
    >
      <span class="icon">
        <SVGIcon cls="icon" faSVG={icon} />
      </span>
      <span class={classNames("title", titleClass)}>{title}</span>
      {isRecord ? (
        <span class="subtitle" data-desc={recordData.desc}>
          {recordData.title}
        </span>
      ) : null}
    </div>
  );
};

// import {
//   calcRecordTotal,
//   calcExtraRecordTotal,
//   calcBonusRule,
//   formatRuleDesc,
// } from "../helper/raidPaymentCalc";

const RaidSummary = (props) => {
  const raidRecords = useSelector((state) => {
    return Object.values(state.records.records).filter(
      (r) => !r.isDisenchant || r.price != 0
    );
  }, shallowEqual);

  const raidExtraRecords = useSelector((state) => {
    return Object.values(state.extraRecords.records).filter(
      (r) => r.price != 0
    );
  }, shallowEqual);

  const raidBonusRules = useSelector(
    (state) => Object.values(state.config.bonusList),
    shallowEqual
  );

  const totalIncoming = useMemo(() => {
    const recordTotal = calcRecordTotal(raidRecords);
    const extraRecordTotal = calcExtraRecordTotal(raidExtraRecords);
    // console.log(recordTotal, extraRecordTotal);
    return recordTotal + extraRecordTotal;
  }, [raidRecords, raidExtraRecords]);

  const [raidTax, setRaidTax] = useState(0);

  const bonusCards = useMemo(() => {
    const cards = [];
    let raidTax = 0;
    for (const rule of raidBonusRules) {
      const [amount, totalCost] = calcBonusRule(rule, totalIncoming);
      raidTax += totalCost;
      cards.push({
        title: Math.floor(amount),
        icon: BONUS_TYPE_SVG[rule.recordType],
        isRecord: true,
        recordData: {
          title: rule.recordTitle,
          desc: formatRuleDesc(rule),
        },
      });
    }
    setRaidTax(raidTax);
    return cards;
  }, [totalIncoming, raidBonusRules]);

  const raidSplit = useSelector((state) => {
    return state.config.splitCut;
  }, shallowEqual);

  const paymentCards = useMemo(() => {
    const afterTaxAmount = totalIncoming - raidTax;
    const payment = Math.floor(afterTaxAmount / raidSplit);
    return [
      {
        title: payment,
        icon: faCoins,
        titleClass: "is-gold",
      },
      {
        title: payment * 5,
        icon: faUserGroup,
        titleClass: "is-gold",
      },
    ];
  }, [raidSplit, raidTax]);

  return (
    <aside class="raid-summary">
      <div class="raid-card-container">
        <div class="card gold-card">
          <div class="group-left">
            <span class="icon">
              <span class="x-icon x-icon-gold2"></span>
            </span>
          </div>
          <div class="group-right">
            <span class="title">{totalIncoming}</span>
            <span class="subtitle">Total</span>
          </div>
        </div>
        <Card
          {...{
            title: Math.floor(raidSplit),
            icon: faUser,
            isDouble: true,
            enableCopy: false,
            titleClass: raidSplit % 1 == 0.5 ? "is-half-cut" : "",
          }}
        />
        {paymentCards.map((card) => (
          <Card {...card} />
        ))}
        {bonusCards.map((card) => (
          <Card {...card} />
        ))}
      </div>
    </aside>
  );
};

const RaidViewMain = (props) => {
  const { instanceID } = props;
  const isLoading = useSelector(
    (state) => state.internalStatus?.isLoading !== false,
    shallowEqual
  );
  const instanceNotFound = useSelector(
    (state) => state.internalStatus?.instanceNotFound === true,
    shallowEqual
  );
  const raidType = useSelector((state) => state.raidType, shallowEqual);

  return (
    <RaidContext.Provider value={RAID[raidType] || {}}>
      <div
        class={classNames("raid-containter", {
          "is-loading": isLoading || instanceNotFound,
        })}
      >
        {isLoading ? (
          <div class="raid-message">
            <span class="message">Loading Raid Record...</span>
          </div>
        ) : null}
        {instanceNotFound ? (
          <div class="raid-message is-not-found">
            <span class="icon">
              <SVGIcon cls="icon" faSVG={faSkull} />
            </span>
            <span class="error-message">Raid record not found.</span>
            <button class="button is-text" onClick={() => route("/", true)}>
              Go Back
            </button>
          </div>
        ) : null}
        <RaidControl />
        <RaidAllReocrds isLoading={isLoading || instanceNotFound} />
        <RaidExtraRecords isLoading={isLoading || instanceNotFound} />
        <RaidSummary isLoading={isLoading || instanceNotFound} />
      </div>
    </RaidContext.Provider>
  );
};

import { raidStore } from "../store/raidStore";
import { Fragment } from "preact";

export const RaidView = (props) => {
  const { instanceID } = props;

  useEffect(() => {
    raidStore.dispatch({
      type: "__self/loadInstance",
      payload: instanceID,
    });
  }, [instanceID]);

  return (
    <Provider store={raidStore}>
      <RaidViewMain instanceID={instanceID} />
    </Provider>
  );
};
