import PouchDB from "pouchdb";
import {default as pdb_find} from "pouchdb-find";

PouchDB.plugin(pdb_find);
// import { RAID_TYPE } from "~src/const"

// gdkp:3fda3770f59790e35
const remote_db = new PouchDB(
  "https://gdkp:3fda3770f59790e35@ah_api.c70.ca/c5a940/db/gdkp_sheet"
);

export const getRaidData = async (instanceID) => {
  // return (await db).get("RaidStore", instanceID);
  // return await db.get(instanceID);
  try {
    return await remote_db.get(instanceID);
  } catch (err) {
    return null;
  }
};

export const updateRaidData = async (data) => {
  console.log("[DB] Update Raid Data", data);
  // return (await db).put("RaidStore", data);
  const doc = await getRaidData(data.instanceID);
  data._id = data.instanceID;
  delete data._rev;
  if (doc) {
    console.log("[DB] Update, doc", doc);
    data._rev = doc._rev;
  }
  console.log("[DB] Update, Final", data);
  return await remote_db.put(data);
};

export const removeRaidData = async (instanceID) => {
  // return (await db).delete("RaidStore", instanceID);
  const doc = await getRaidData(instanceID);
  return await remote_db.remove(doc);
};

export const getAllRaidData = async () => {
  // return (await db).getAll("RaidStore");
  let raids = await remote_db.query("index/allRaid");
  // console.log(raids);
  return raids ? raids.rows.map((d) => d.value) : [];
};

// export const updateRaidData = async (data) => {
//   return (await db).put("RaidStore", data);
// };

// export const getRaidData = async (instanceID) => {
//   return (await db).get("RaidStore", instanceID);
// };

// export const removeRaidData = async (instanceID) => {
//   return (await db).delete("RaidStore", instanceID);
// };

// export const getAllRaidData = async () => {
//   return (await db).getAll("RaidStore");
// };

const templates_db = new PouchDB("gdkp_templates");
// const templates_remote = new PouchDB('http://gdkp:3fda3770f59790e35@10.100.0.102:15984/gdkp_templates');
const templates_sync = PouchDB.sync(
  "gdkp_templates",
  "https://gdkp:3fda3770f59790e35@ah_api.c70.ca/c5a940/db/gdkp_templates_new",
  { live: true, retry: true }
);

export const getTemplate = async (templateID) => {
  try {
    return await templates_db.get(templateID);
  } catch (err) {
    return null;
  }
};

export const updateTemplate = async (data) => {
  // console.log("[DB] Update Raid Data", data);
  // return (await db).put("RaidStore", data);
  const doc = await getTemplate(data.templateID);
  data._id = data.templateID;
  if (doc) {
    // console.log("[DB] Update, doc", doc);
    data._rev = doc._rev;
  }
  // console.log("[DB] Update, Final", data);
  return await templates_db.put(data);
};

export const deleteTemplate = async (templateID) => {
  const doc = await getTemplate(templateID);
  return await templates_db.remove(doc);
};

export const getAllTemplate = async () => {
  let data = await templates_db.query("index/allTemplate");
  console.log(data);
  return data ? data.rows.map((d) => d.value) : [];
};

export const getTemplatesByType = async (raidType) => {
  let results = await templates_db.find({
    selector: { raidType: raidType },
    fields: ["_id", "templateName"],
    // sort: ["templateName"],
  });
  return results.docs ? results.docs.map((d) => [d._id, d.templateName]) : [];
};

// export const getTemplatesByType = async (raidType) => {
//   let results = [];
//   const typeIndex = (await db)
//     .transaction("RaidTemplate")
//     .store.index("raidType");
//   for await (const cursor of typeIndex.iterate(raidType)) {
//     results.push([cursor.value.templateID, cursor.value.templateName]);
//   }
//   return results;
// };

// export const getAllTemplate = async () => {
//   return (await db).getAll("RaidTemplate");
// };

// export const updateTemplate = async (data) => {
//   return (await db).put("RaidTemplate", data);
// };

// export const deleteTemplate = async (templateID) => {
//   return (await db).delete("RaidTemplate", templateID);
// };

// export const getTemplate = async (templateID) => {
//   return (await db).get("RaidTemplate", templateID);
// };
