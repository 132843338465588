import { SVGIcon } from "~src/components/SVGIcon";
import { RAID_NAME } from "~/src/const";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

import { NoRaidRecord } from "./InfoScreen";
import { route } from "preact-router";

import {
  selectRaidById,
  selectRaidGroupIds,
  removeRaid,
} from "~src/store/homeStore";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import classNames from "classnames";
import * as dayjs from "dayjs";

export const RecrodCard = ({ recordID, dispatch }) => {
  const record = useSelector(
    (state) => selectRaidById(state, recordID) || {},
    shallowEqual
  );
  // const record = useSelector()
  const removeRecord = (e) => {
    e.stopPropagation();
    if (window.confirm("Remove record?")) {
      dispatch(removeRaid({ recordID: recordID }));
    }
  };

  const handleRaidOpen = (event) => {
    route(`/raid/${recordID}`);
    // event.preventDefault();
    // if(event.target === event.currentTarget) {
    //   route(`/raid/${recordID}`)
    // }
  };
  return (
    <div
      class={classNames(
        "card",
        "is-" + record.raidType?.toLowerCase().replace("_", "-")
      )}
    >
      <div class="layer" onClick={handleRaidOpen}>
        <span class="title">
          {record.title || RAID_NAME[record.raidType]?.full}
        </span>
        <span class="subtitle">{dayjs(record.date).format("D MMM, YYYY")}</span>
        <button class="button btn-remove" onClick={removeRecord}>
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faXmark} />
          </span>
        </button>
      </div>
    </div>
  );
};

export const RecordContainter = (props) => {
  // const ids = useSelector((state) => selectRaidIds(state));
  const grouped_ids = useSelector(selectRaidGroupIds, shallowEqual);
  const dispatch = useDispatch();
  return (
    <div class="record-containter">
      {grouped_ids.length > 0 ? (
        grouped_ids.map((group) => (
          <section class="group">
            <header>{group[0]}</header>
            {group[1].map((id) => (
              <RecrodCard key={id} recordID={id} dispatch={dispatch} />
            ))}
          </section>
        ))
      ) : (
        <NoRaidRecord />
      )}
    </div>
  );
};
