import { ItemSelect } from "./ItemSelect";
import { ItemContainter } from "./ItemContainter";
import { RaidContext } from "../collection/RaidContext";
import { ITEM_TYPE } from "../const";

import classNames from "classnames/bind";

import { SVGIcon } from "./SVGIcon";
import {
  // faEllipsisVertical,
  faXmark,
  faCheck,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  useMemo,
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "preact/hooks";

import { useStore, useDispatch, shallowEqual, useSelector } from "react-redux";

export const RecordMenu = (props) => {
  const {
    isDisenchant,
    toggleDisenchant,
    removeThisRecord,
    disableDisenchant = false,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const doToggleDisenchant = useCallback(() => {
    toggleOpen();
    toggleDisenchant();
  });

  const doRemoveThisRecord = useCallback(() => {
    toggleOpen();
    removeThisRecord();
  });

  const ref = useRef();
  const openMenu = () => {
    const closeMenu = () => {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    };

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeMenu();
      }
    }

    if (isOpen) {
      closeMenu();
    } else {
      setIsOpen(true);
      document.addEventListener("mousedown", handleClickOutside);
    }
  };

  return (
    <div class="dropdown-menu" ref={ref}>
      <button
        class={classNames("button", "no-bg", { show: isOpen })}
        onClick={openMenu}
      >
        <span class="icon">
          <SVGIcon cls="icon" faSVG={isOpen ? faCaretRight : faCaretLeft} />
        </span>
      </button>
      {isOpen ? (
        <div class="menu-context">
          <button class="button no-bg" onClick={doRemoveThisRecord}>
            <span class="icon">
              <SVGIcon cls="icon" faSVG={faXmark} />
            </span>
          </button>
          {disableDisenchant ? null : (
            <button class="button no-bg" onClick={doToggleDisenchant}>
              <span
                class={classNames(
                  "x-icon",
                  isDisenchant ? "x-icon-gold" : "x-icon-disenchant"
                )}
              ></span>
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export const ItemPrice = (props) => {
  const { price, isDisenchant, priceUpdate } = props;
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();
  const toggleMenu = () => {
    const closeMenu = () => {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    };

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeMenu();
      }
    }

    if (isOpen) {
      closeMenu();
    } else {
      setIsOpen(true);
      document.addEventListener("mousedown", handleClickOutside);
    }
  };

  const inputRef = useRef(null);
  const doPriceUpdate = () => {
    priceUpdate(parseInt(inputRef.current?.value || 0));
    inputRef.current.blur();
    toggleMenu();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doPriceUpdate();
    }
  };

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div class="price-wrapper" ref={ref}>
      {isDisenchant ? (
        <span class="x-icon x-icon-disenchant"></span>
      ) : (
        <div class="price-context" onClick={toggleMenu}>
          <span class="x-icon x-icon-gold"></span>
          <span class="price-text">{price}</span>
        </div>
      )}
      {isOpen ? (
        <div class="price-dropdown">
          <div class="control has-icon-right">
            <input
              ref={inputRef}
              class="input"
              placeholder=""
              type="number"
              onKeyDown={handleKeyDown}
              value={price == "0" ? "" : price}
            />
            <span class="icon is-right" onClick={doPriceUpdate}>
              <SVGIcon cls="icon is-green is-hover" faSVG={faCheck} />
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const SingleRecord = (props) => {
  const { record_id } = props;
  const [result, setResult] = useState({ type: ITEM_TYPE.STRING, value: "" });
  const [isOpen, setIsOpen] = useState(false);

  const raidItemList = useContext(RaidContext);
  const store = useStore();
  const dispatch = useDispatch();

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const isDisenchant = useSelector((state) => {
    const record = state.records.records[record_id];
    return record?.isDisenchant;
  }, shallowEqual);

  const price = useSelector((state) => {
    const record = state.records.records[record_id];
    return record?.price || 0;
  }, shallowEqual);

  const toggleDisenchant = () => {
    dispatch({
      type: "raid/record/update",
      payload: {
        id: record_id,
        isDisenchant: !isDisenchant,
      },
    });
  };

  const priceUpdate = (new_price) => {
    dispatch({
      type: "raid/record/update",
      payload: {
        id: record_id,
        price: new_price,
      },
    });
  };

  const removeThisRecord = () => {
    dispatch({
      type: "raid/record/remove",
      payload: record_id,
    });
  };

  useEffect(() => {
    const records = store.getState()["records"]["records"];
    const current = records[record_id];
    // console.log(records, current, record_id, result)
    setResult(current.data);
  }, [record_id]);

  const itemData = useMemo(() => {
    switch (result.type) {
      case ITEM_TYPE.STRING:
        return {
          i: result.value ? "wow_token01" : "inv_misc_bandage_07",
          t: result.value || "[New Entry]",
          tc: "",
          q: result.value ? 6 : 0,
          text: true,
        };
      case ITEM_TYPE.ITEM_ID:
        //setValue get item from list
        return (
          raidItemList[result.value] || {
            id: result.value,
            i: "inv_misc_questionmark",
            t: `Unknown Item: ${result.value}`,
            tc: "",
            q: 0,
          }
        );
    }
  }, [result]);

  const doSetResult = (data) => {
    setResult(data);
    dispatch({
      type: "raid/record/update",
      payload: {
        id: record_id,
        data: data,
      },
    });
  };

  return (
    <div class="raid-record-row">
      <div class="item-wrapper">
        <ItemContainter item_data={itemData} toggleOpen={toggleOpen} />
        <ItemSelect
          result={result}
          setResult={doSetResult}
          isOpen={isOpen}
          toggleOpen={toggleOpen}
        />
      </div>
      <ItemPrice
        isDisenchant={isDisenchant}
        price={price}
        priceUpdate={priceUpdate}
      />
      <RecordMenu
        removeThisRecord={removeThisRecord}
        toggleDisenchant={toggleDisenchant}
        isDisenchant={isDisenchant}
      />
    </div>
  );
};
