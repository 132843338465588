export const SVGIcon = (props) => {
  const { cls, style, faSVG } = props;
  const view = [0, 0, faSVG.icon[0], faSVG.icon[1]];
  const path = faSVG.icon[4];
  return (
    <i class={cls} style={style}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={view.join(" ")}>
        <path d={path} />
      </svg>
    </i>
  );
};
