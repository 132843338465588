import { SVGIcon } from "~src/components/SVGIcon";
import { RAID_NAME } from "~/src/const";
import { faXmark, faPlus } from "@fortawesome/pro-solid-svg-icons";

import { NoTemplateRecord } from "./InfoScreen";
import { route } from "preact-router";

import { RAID_NAME } from "~src/const"
import { createRaidFromTemplate } from "~src/helper/raidTemplates";

import {
  selectTemplateById,
  selectTemplateGroupIds,
  removeTemplate,
} from "~src/store/homeStore";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import classNames from "classnames";
import * as dayjs from "dayjs";

export const TemplateCard = ({ recordID, dispatch }) => {
  const record = useSelector(
    (state) => selectTemplateById(state, recordID) || {},
    shallowEqual
  );
  // const record = useSelector()
  const removeRecord = (e) => {
    e.stopPropagation();
    if (window.confirm("Remove Template?")) {
      dispatch(removeTemplate({ recordID: recordID }));
      // console.log("remved");
    }
  };

  const handleTemplateCreate = async (event) => {
    if (window.confirm(`Create Raid from ${record.title}?`)) {
      const newRaidID = await createRaidFromTemplate("", record.raidType, record.id);
      route(`/raid/${newRaidID}`);
    }
  };
  return (
    <div
      class={classNames(
        "card",
        "is-template",
        "is-" + record.raidType?.toLowerCase().replace("_", "-")
      )}
    >
      <div class="layer" onClick={handleTemplateCreate}>
        <span class="title">
          {record.title}
        </span>
        <span class="subtitle">{dayjs(record.date).format("D MMM, YYYY")}</span>
        <button class="button btn-remove" onClick={removeRecord}>
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faXmark} />
          </span>
        </button>
        <button class="button btn-add">
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faPlus} />
          </span>
        </button>
      </div>
    </div>
  );
};

export const TemplateContainter = (props) => {
  // const ids = useSelector((state) => selectRaidIds(state));
  const grouped_ids = useSelector(selectTemplateGroupIds, shallowEqual);
  const dispatch = useDispatch();
  return (
    <div class="record-containter is-template">
      {grouped_ids ? (
        Object.entries(RAID_NAME).map(([raidType, raidName]) => {
          const group = grouped_ids[raidType];
          if (group) {
            return (
              <section class="group">
                <header>{raidName.full}</header>
                {group.map((id) => (
                  <TemplateCard key={id} recordID={id} dispatch={dispatch} />
                ))}
              </section>
            )
          }
        }
        )
      ) : (
        <NoTemplateRecord />
      )}
    </div>
  );
};
