import {
  configureStore,
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { nanoid } from "nanoid";
import * as dayjs from "dayjs";
import { sortBy as _sortBy } from "underscore";

import {
  removeRaidData,
  getAllRaidData,
  getAllTemplate,
  deleteTemplate,
} from "../db/raidDB";

const templateAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.date - a.date,
});

export const { selectById: selectTemplateById, selectIds: selectTemplateIds } =
  templateAdapter.getSelectors((state) => state.template);

export const fetchTemplate = createAsyncThunk(
  "home/raidTemplate/fetchAll",
  async () => {
    const records = await getAllTemplate();
    // console.log("home/raidTemplate/fetchAll", records);
    let state = [];
    for (const record of records) {
      state.push(record);
      // state.push({
      //   id: record.templateID,
      //   title: record.templateName,
      //   date: record.templateDate,
      //   raidType: record.raidType,
      //   // recordCount: Object.keys(record.records.records).length
      // });
    }

    return state;
  }
);

const updateTemplateGroups = (state) => {
  if (state.ids.length < 1) {
    state.group = [];
    return
  }
  let group = [];
  for (const record of Object.values(state.entities)) {
    const gKey = record.raidType;
    if (group[gKey]) {
      group[gKey][record.id] = record.date;
    } else {
      group[gKey] = { [record.id]: record.date };
    }

    state.group = Object.fromEntries(Object.keys(group).map((k) => [
      k,
      _sortBy(Object.entries(group[k]), (i) => -i[1]).map((i) => i[0]),
    ]));
  }
};

const templateSlice = createSlice({
  name: "template",
  initialState: templateAdapter.getInitialState({
    status: "idle",
    error: null,
    group: [],
  }),
  reducers: {
    removeTemplate: (state, action) => {
      const { recordID } = action.payload;
      console.log("Remove: ", recordID);
      templateAdapter.removeOne(state, recordID);
      deleteTemplate(recordID);
      updateTemplateGroups(state);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTemplate.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTemplate.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        templateAdapter.setAll(state, action.payload);
        // state.group = action.payload.group
        updateTemplateGroups(state);
      })
      .addCase(fetchTemplate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectTemplateGroupIds = (state) => state.template.group;
export const { removeTemplate } = templateSlice.actions;

const raidAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.date - a.date,
});

export const { selectById: selectRaidById, selectIds: selectRaidIds } =
  raidAdapter.getSelectors((state) => state.raid);

export const fetchRaid = createAsyncThunk(
  "home/raidCard/fetchAll",
  async () => {
    const raidRecords = await getAllRaidData();
    // console.log(raidRecords)
    console.log("home/raidCard/fetchAll");
    let state = [];
    let group = {};

    for (const record of raidRecords) {
      // state.push({
      //   id: record.instanceID,
      //   title: record.config.title,
      //   date: record.config.raidDate,
      //   raidType: record.raidType,
      //   // recordCount: Object.keys(record.records.records).length
      // });
      // group
      state.push(record);
      const gKey = dayjs(record.date).format("YYYYMM");
      if (group[gKey]) {
        group[gKey][record.id] = record.date;
      } else {
        group[gKey] = { [record.id]: record.date };
      }
    }

    // groupKeys = Object.keys(group)
    // groupKeys.sort()
    // groupKeys.reverse()

    group = _sortBy(Object.keys(group), (i) => -i).map((k) => [
      dayjs(k, "YYYYMM").format("MMM, YYYY"),
      _sortBy(Object.entries(group[k]), (i) => -i[1]).map((i) => i[0]),
    ]);

    return { state: state, group: group };
  }
);

const updateGroupIds = (state) => {
  if (state.ids.length < 1) {
    state.group = [];
    return
  }

  let group = [];
  for (const record of Object.values(state.entities)) {
    const gKey = dayjs(record.date).format("YYYYMM");
    if (group[gKey]) {
      group[gKey][record.id] = record.date;
    } else {
      group[gKey] = { [record.id]: record.date };
    }

    state.group = _sortBy(Object.keys(group), (i) => -i).map((k) => [
      dayjs(k, "YYYYMM").format("MMM, YYYY"),
      _sortBy(Object.entries(group[k]), (i) => -i[1]).map((i) => i[0]),
    ]);
  }
};

const raidSlice = createSlice({
  name: "raid",
  initialState: raidAdapter.getInitialState({
    status: "idle",
    error: null,
    group: [],
  }),
  reducers: {
    removeRaid: (state, action) => {
      const { recordID } = action.payload;
      console.log("Remove: ", recordID);
      raidAdapter.removeOne(state, recordID);
      removeRaidData(recordID);
      updateGroupIds(state);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRaid.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchRaid.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        // state.posts = state.posts.concat(action.payload)
        raidAdapter.setAll(state, action.payload.state);
        state.group = action.payload.group;
      })
      .addCase(fetchRaid.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectRaidGroupIds = (state) => state.raid.group;
export const { removeRaid } = raidSlice.actions;

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    isOpen: false,
    content: null,
  },
  reducers: {
    setModalContent: (state, action) => {
      console.log(action);
      state.content = action.payload;
      state.isOpen = true;
    },
    toggleModal: (state) => {
      // state.isOpen = !state.isOpen;
      state.isOpen = false;
      state.content = null;
    },
    setModelOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const selectModalContent = (state) => state.modal.content;
export const selectModalOpen = (state) => state.modal.isOpen;

export const { setModalContent, toggleModal, setModelOpen } =
  modalSlice.actions;

export const homeStore = configureStore({
  reducer: {
    modal: modalSlice.reducer,
    raid: raidSlice.reducer,
    template: templateSlice.reducer,
  },
});
