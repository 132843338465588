import { SVGIcon } from "~src/components/SVGIcon";
import { RecordContainter } from "./RecordContainter";
import { TemplateContainter } from './TemplateContainter'
import { LoadingScreen, ErrorScreen } from "./InfoScreen";
import { ModalContainter } from "./Modal";

import { homeStore, fetchRaid, fetchTemplate } from "~src/store/homeStore";
import { createNewRaid } from "./Modal";

import { debounce } from "underscore";

import {
  faPlus,
  faGrid2,
  faRotate,
  faTableLayout,
  faTableList,
} from "@fortawesome/pro-solid-svg-icons";
import { useState, useRef, useContext, useEffect, useMemo } from "preact/hooks";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Fragment } from "preact";
import { toast } from "react-toastify";

const RaidList = () => {
  const dispatch = useDispatch();
  const raidStatus = useSelector((state) => state.raid.status);
  const error = useSelector((state) => state.raid.error);

  useEffect(() => {
    dispatch(fetchRaid());
  }, []);

  let contents;

  switch (raidStatus) {
    case "loading": {
      contents = <LoadingScreen />;
      break;
    }
    case "succeeded": {
      contents = <RecordContainter />;
      break;
    }
    case "failed": {
      contents = <ErrorScreen msg={error} />;
      break;
    }
  }

  return <Fragment>{contents}</Fragment>;
};

const TemplateList = () => {
  const dispatch = useDispatch();
  const raidStatus = useSelector((state) => state.template.status);
  const error = useSelector((state) => state.template.error);

  useEffect(() => {
    dispatch(fetchTemplate());
  }, []);

  let contents;

  switch (raidStatus) {
    case "loading": {
      contents = <LoadingScreen />;
      break;
    }
    case "succeeded": {
      contents = <TemplateContainter />;
      break;
    }
    case "failed": {
      contents = <ErrorScreen msg={error} />;
      break;
    }
  }

  return <Fragment>{contents}</Fragment>;
};

export const HomeView = (props) => {
  const [isTempalte, setIsTemplate] = useState(false);

  const handleRefresh = debounce(
    () => {
      if (isTempalte) {
        console.log("isTempalte");
      } else {
        homeStore.dispatch(fetchRaid());
      }
      toast("Refresh Done.");
    },
    5000,
    true
  );
  return (
    <div class="home-containter">
      <Provider store={homeStore}>
        <main class="home-main">
          <div class="record-control raid-control">
            <div class="raid-title">
              <span class="icon pad">
                <SVGIcon cls="icon" faSVG={faGrid2} />
              </span>
              <span class="title">{isTempalte ? "All Templates" : "All Records"}</span>
            </div>
            {isTempalte ? null : (
              <button class="button" onClick={createNewRaid}>
                <span class="icon">
                  <SVGIcon cls="icon" faSVG={faPlus} />
                </span>
              </button>
            )}
            <button class="button" onClick={handleRefresh}>
              <span class="icon">
                <SVGIcon cls="icon" faSVG={faRotate} />
              </span>
            </button>
            <button
              class="button"
              onClick={() => {
                setIsTemplate(!isTempalte);
              }}
            >
              <span class="icon">
                <SVGIcon
                  cls="icon"
                  faSVG={isTempalte ? faTableList : faTableLayout}
                />
              </span>
            </button>
          </div>
          {isTempalte ? <TemplateList /> : <RaidList />}
        </main>
        <aside class="home-aside"></aside>
        <ModalContainter />
      </Provider>
    </div>
  );
};
