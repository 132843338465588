import classNames from "classnames";
import { SVGIcon } from "~src/components/SVGIcon";
import {
  faMonument,
  faPuzzlePiece,
  faBug,
} from "@fortawesome/pro-solid-svg-icons";

import { createNewRaid } from "./Modal";

export const InfoScreen = (props) => {
  const { icon, cls, children } = props;
  return (
    <div class={classNames("home-info-screen", cls)}>
      {icon ? (
        <span class="icon">
          <SVGIcon cls="icon" faSVG={icon} />
        </span>
      ) : null}
      {children}
    </div>
  );
};

export const LoadingScreen = () => {
  return (
    <InfoScreen cls="loading">
      <span class="is-loading"></span>
      <span class="title">Loading...</span>
    </InfoScreen>
  );
};

export const NoRaidRecord = () => {
  return (
    <InfoScreen cls="ops" icon={faMonument}>
      <span class="title">Ops. No records yet.</span>
      <button class="button is-text fix" onClick={createNewRaid}>
        Create
      </button>
    </InfoScreen>
  );
};

export const NoTemplateRecord = () => {
  return (
    <InfoScreen cls="ops" icon={faPuzzlePiece}>
      <span class="title">Ops. No templates yet.</span>
    </InfoScreen>
  );
};

export const ErrorScreen = (props) => {
  const { msg } = props;
  return (
    <InfoScreen cls="ops" icon={faBug}>
      <span class="title">Ops. Something Wrong.</span>
      <span class="subtitle">{msg}</span>
    </InfoScreen>
  );
};
