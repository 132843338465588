import {
  EXTRA_RECORD_TYPE,
  BONUS_TYPE,
  BONUS_CALC_TYPE,
  BONUS_SPLIT_TYPE,
} from "../const";

export const calcRecordTotal = (records) => {
  let total = 0;
  for (const record of records) {
    const price = parseInt(record.price || 0);

    if (record.isDisenchant || price == 0) {
      continue;
    }

    total = total + price;
  }

  return total;
};

export const calcExtraRecordTotal = (records) => {
  let total = 0;
  for (const record of records) {
    let sign = 1;
    const price = parseInt(record.price || 0);

    if (record.isDisenchant || price == 0) {
      continue;
    }

    if (record.type == EXTRA_RECORD_TYPE.EXPENSE) {
      sign = -1;
    }
    total = total + price * sign;
    // console.log(total, price, sign, record.price);
  }

  return total;
};

export const calcBonusRule = (rule, total) => {
  let amount =
    rule.calcType == BONUS_CALC_TYPE.TYPE_PERCENT
      ? total * rule.calcValue * 0.01
      : rule.calcValue;
  let totalAmount = amount;

  if (rule.splitType == BONUS_SPLIT_TYPE.TYPE_EACH) {
    totalAmount = amount * rule.splitValue;
  } else if (rule.splitType == BONUS_SPLIT_TYPE.TYPE_SPLIT) {
    amount = amount / rule.splitValue;
  }

  return [amount, totalAmount];
};

const calcTypeMap = {
  [BONUS_CALC_TYPE.TYPE_FIXED]: "G",
  [BONUS_CALC_TYPE.TYPE_PERCENT]: "%",
};
const splitTypeMap = {
  [BONUS_SPLIT_TYPE.TYPE_EACH]: "x",
  [BONUS_SPLIT_TYPE.TYPE_SPLIT]: "/",
};
export const formatRuleDesc = (rule) => {
  let result = `${rule.calcValue}${calcTypeMap[rule.calcType]}`;
  if (rule.splitValue > 1) {
    result += ` ${splitTypeMap[rule.splitType]} ${rule.splitValue}`;
  }
  return result;
};
