import { createContext } from "preact";
import * as tbc_bt from "./tbc_bt";
import * as tbc_swp from "./tbc_swp";
import * as wotlk_naxx from "./wotlk_naxx";
import * as wotlk_ob from "./wotlk_ob";
import * as wotlk_eoe from "./wotlk_eoe";

import { RAID_TYPE } from "~/src/const";

export const RAID = {
  [RAID_TYPE.TBC_BT]: tbc_bt,
  [RAID_TYPE.TBC_SWP]: tbc_swp,
  [RAID_TYPE.WOTLK_NAXX]: wotlk_naxx,
  [RAID_TYPE.WOTLK_OB]: wotlk_ob,
  [RAID_TYPE.WOTLK_EOE]: wotlk_eoe,
};

export const RaidContext = createContext({});
export const getRaidItemContext = (raidType) => RAID[raidType] || {};
