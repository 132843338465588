import { RecordMenu, ItemPrice } from "./SingleRecord";
import { EXTRA_RECORD_TYPE } from "../const";

import classNames from "classnames/bind";
import { SVGIcon } from "./SVGIcon";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

import {
  useMemo,
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "preact/hooks";
import { useStore, useDispatch, shallowEqual, useSelector } from "react-redux";

const ItemContainter = (props) => {
  const { record_id, recordTitle, recordType, dispatch } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const toggleMenu = () => {
    const closeMenu = () => {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    };

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeMenu();
      }
    }

    if (isOpen) {
      closeMenu();
    } else {
      setIsOpen(true);
      document.addEventListener("mousedown", handleClickOutside);
    }
  };

  const inputRef = useRef(null);
  const doRecordUpdate = () => {
    // updateRecord(parseInt(inputRef.current?.value || 0));
    // dispatch
    //console.log(inputRef.current?.value);
    dispatch({
      type: "raid/extra_record/update",
      payload: {
        id: record_id,
        title: inputRef.current?.value,
      },
    });
    inputRef.current.blur();
    toggleMenu();
  };

  const recordTypeUpdate = (value) => {
    dispatch({
      type: "raid/extra_record/update",
      payload: {
        id: record_id,
        type: value,
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doRecordUpdate();
    }
  };

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div class="media">
      <div class="media-left">
        <figure class="image is-32x32">
          <img
            src="https://cros-wow.c70.ca/images/wow/icons/medium/wow_token01.jpg"
            alt=""
          />
        </figure>
      </div>
      <div class="media-content">
        <p class="title" onClick={toggleMenu}>
          <a class="is-label">{recordTitle}</a>
        </p>
        <p
          class={classNames("subtitle", {
            "is-credit": recordType == EXTRA_RECORD_TYPE.CREDIT,
            "is-expense": recordType == EXTRA_RECORD_TYPE.EXPENSE,
          })}
        ></p>
      </div>
      {isOpen ? (
        <div class="record-edit-menu" ref={ref}>
          <div class="type-select">
            <label>
              <input
                type="radio"
                name="record-type"
                checked={recordType == EXTRA_RECORD_TYPE.CREDIT}
                onChange={() => recordTypeUpdate(EXTRA_RECORD_TYPE.CREDIT)}
              />
              <div class="box">
                <span>Credit</span>
              </div>
            </label>
            <label>
              <input
                type="radio"
                name="record-type"
                checked={recordType == EXTRA_RECORD_TYPE.EXPENSE}
                onChange={() => recordTypeUpdate(EXTRA_RECORD_TYPE.EXPENSE)}
              />
              <div class="box">
                <span>Expense</span>
              </div>
            </label>
          </div>
          <div class="control has-icon-right">
            <input
              ref={inputRef}
              class="input"
              placeholder=""
              onKeyDown={handleKeyDown}
              value={recordTitle}
            />
            <span class="icon is-right" onClick={doRecordUpdate}>
              <SVGIcon cls="icon is-green is-hover" faSVG={faCheck} />
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const SingleRecordExtra = (props) => {
  const { record_id } = props;

  // const store = useStore();
  const dispatch = useDispatch();

  const recordTitle = useSelector((state) => {
    const record = state.extraRecords.records[record_id];
    return record?.title;
  }, shallowEqual);

  const recordType = useSelector((state) => {
    const record = state.extraRecords.records[record_id];
    return record?.type;
  }, shallowEqual);

  const price = useSelector((state) => {
    const record = state.extraRecords.records[record_id];
    return record?.price || 0;
  }, shallowEqual);

  const priceUpdate = (value) => {
    dispatch({
      type: "raid/extra_record/update",
      payload: {
        id: record_id,
        price: value,
      },
    });
  };

  const removeThisRecord = () => {
    dispatch({
      type: "raid/extra_record/remove",
      payload: record_id,
    });
  };

  return (
    <div class="raid-record-row">
      <div class="item-wrapper">
        <ItemContainter
          {...{
            record_id: record_id,
            recordTitle: recordTitle,
            recordType: recordType,
            price: price,
            dispatch: dispatch,
          }}
        />
      </div>
      <ItemPrice isDisenchant={false} price={price} priceUpdate={priceUpdate} />
      <RecordMenu
        removeThisRecord={removeThisRecord}
        disableDisenchant={true}
      />
    </div>
  );
};
