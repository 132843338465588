import { ITEM_TYPE, WOW_COLOR } from "../const";
import classNames from "classnames/bind";

const ItemTitle = (props) => {
  const { title, q, item_id, noLink, onClick } = props;
  const nop = (e) => {
    e.preventDefault();
  };

  return (
    <p class="title" onClick={onClick}>
      {noLink ? (
        <a style={{ color: WOW_COLOR[q] }}>{title}</a>
      ) : (
        <a
          href={`https://www.wowhead.com/wotlk/item=${item_id}`}
          target="_blank"
          rel="noreferrer"
          onClick={nop}
          style={{ color: WOW_COLOR[q] }}
        >
          {title}
        </a>
      )}
    </p>
  );
};

export const ItemContainter = (props) => {
  const { item_data, isDropdown, toggleOpen } = props;
  const clickHandle = (e) => {
    toggleOpen && toggleOpen();
  };

  return (
    <div class={classNames("media", { dropdown: isDropdown })}>
      <div class="media-left">
        <figure
          class={classNames("image", isDropdown ? "is-24x24" : "is-32x32")}
        >
          <img
            src={`https://cros-wow.c70.ca/images/wow/icons/medium/${item_data.i}.jpg`}
            alt=""
          />
        </figure>
      </div>
      <div class="media-content">
        <ItemTitle
          onClick={clickHandle}
          title={item_data.t}
          q={item_data.q}
          item_id={item_data.id}
          noLink={item_data.text || isDropdown}
        />
        <p class="subtitle">{item_data.tc}</p>
      </div>
    </div>
  );
};
