import {
  ITEM_TYPE,
  EXTRA_RECORD_TYPE,
  BONUS_TYPE,
  BONUS_CALC_TYPE,
  BONUS_SPLIT_TYPE,
  RAID_TYPE,
} from "../const";

import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { nanoid } from "nanoid";

import { toast } from "react-toastify";

import {
  updateRaidData,
  getRaidData,
  updateTemplate,
  removeRaidData,
} from "../db/raidDB";

import { route } from "preact-router";

const initialStateRecords = {
  records: {},
};

const recordReducer = (state = initialStateRecords, action) => {
  switch (action.type) {
    case "raid/record/new": {
      const { id = nanoid(8), data = { type: ITEM_TYPE.STRING, value: "" } } =
        action.payload || {};
      return {
        ...state,
        records: {
          ...state.records,
          [id]: {
            data: data,
            price: 0,
            isDisenchant: false,
          },
        },
      };
    }
    case "raid/record/update": {
      const { id, data, price, isDisenchant } = action.payload;
      const record = state.records[id];

      return {
        ...state,
        records: {
          ...state.records,
          [id]: {
            data: data || record.data,
            price: price === undefined ? record.price : price,
            isDisenchant:
              isDisenchant === undefined ? record.isDisenchant : isDisenchant,
          },
        },
      };
    }
    case "raid/record/import": {
      const record_ids = Object.keys(state.records);

      let addRecord = {};
      for (const item of action.payload) {
        if (record_ids.includes(item.id)) {
          continue;
        }
        addRecord[item.id] = {
          data: { type: item.type, value: item.value },
          price: 0,
          isDisenchant: false,
        };
      }

      return {
        ...state,
        records: {
          ...state.records,
          ...addRecord,
        },
      };
    }
    case "raid/record/remove": {
      const records = { ...state.records };
      delete records[action.payload];
      return {
        ...state,
        records: records,
      };
    }
    default:
      return state;
  }
};

// const initialStateExtraRecords = {
//   extraRecords: {}
// };

const extraRecordReducer = (state = initialStateRecords, action) => {
  switch (action.type) {
    case "raid/extra_record/new": {
      const {
        id = nanoid(8),
        title = "",
        type = EXTRA_RECORD_TYPE.CREDIT,
      } = action.payload || {};
      return {
        ...state,
        records: {
          ...state.records,
          [id]: {
            title: title,
            type: type,
            price: 0,
          },
        },
      };
    }
    case "raid/extra_record/update": {
      const { id, title, price, type } = action.payload;
      const record = state.records[id];

      return {
        ...state,
        records: {
          ...state.records,
          [id]: {
            title: title || record.title,
            type: type || record.type,
            price: price === undefined ? record.price : price,
          },
        },
      };
    }
    case "raid/extra_record/remove": {
      const records = { ...state.records };
      delete records[action.payload];
      return {
        ...state,
        records: records,
      };
    }
    default:
      return state;
  }
};

const initialStateConfig = {
  splitCut: 25,
  title: "",
  raidDate: Date.now(),
  bonusList: {
    [nanoid(8)]: {
      recordTitle: "Tank Bonus",
      recordType: BONUS_TYPE.TANK,
      calcType: BONUS_CALC_TYPE.TYPE_PERCENT,
      calcValue: 4,
      splitType: BONUS_SPLIT_TYPE.TYPE_SPLIT,
      splitValue: 3,
    },
    [nanoid(8)]: {
      recordTitle: "Admin Cuts",
      recordType: BONUS_TYPE.ADMIN,
      calcType: BONUS_CALC_TYPE.TYPE_PERCENT,
      calcValue: 2,
      splitType: BONUS_SPLIT_TYPE.TYPE_EACH,
      splitValue: 3,
    },
  },
};

const configReducer = (state = initialStateConfig, action) => {
  switch (action.type) {
    case "raid/config/newBonus": {
      return {
        ...state,
        bonusList: {
          ...state.bonusList,
          [nanoid(8)]: {
            recordTitle: "",
            recordType: BONUS_TYPE.OTHER,
            calcType: BONUS_CALC_TYPE.TYPE_PERCENT,
            calcValue: 0,
            splitType: BONUS_SPLIT_TYPE.TYPE_EACH,
            splitValue: 1,
          },
        },
      };
    }
    case "raid/config/update_config": {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value || state[key],
      };
    }
    case "raid/config/update": {
      const {
        id,
        recordTitle,
        recordType,
        calcType,
        calcValue,
        splitType,
        splitValue,
      } = action.payload;
      const record = state.bonusList[id];

      return {
        ...state,
        bonusList: {
          ...state.bonusList,
          [id]: {
            recordTitle: recordTitle || record.recordTitle,
            recordType: recordType || record.recordType,
            calcType: calcType || record.calcType,
            calcValue: calcValue === undefined ? record.calcValue : calcValue,
            splitType: splitType || record.splitType,
            splitValue:
              splitValue === undefined ? record.splitValue : splitValue,
          },
        },
      };
    }
    default:
      return state;
  }
};

// const sleep = (milliseconds) => {
//   return new Promise(resolve => setTimeout(resolve, milliseconds))
// }

const localSave = (store) => (next) => async (action) => {
  const result = next(action);

  if (action.type == "__self/loadInstance") {
    const instanceId = action.payload;
    const data = (await getRaidData(instanceId)) || {
      instanceID: instanceId,
      noInstance: true,
    };
    delete data._id;
    delete data._rev;
    store.dispatch({
      type: "__self/setInstanceData",
      payload: data,
    });
    if (data.mode == "default") {
      store.dispatch({ type: "__self/forceSave" });
    }
    return result;
  } else if (action.type == "__self/templateCreate") {
    const { title, options } = action.payload;
    const thisRecord = { ...store.getState() };
    delete thisRecord.internalStatus;
    delete thisRecord.instanceID;

    thisRecord.config.title = "";
    thisRecord.templateID = nanoid(8);
    thisRecord.templateName = title;
    thisRecord.templateDate = Date.now();

    if (!options.includeRecord) {
      thisRecord.records.records = {};
    }

    if (!options.includeExtra) {
      thisRecord.extraRecords.records = {};
    }

    await updateTemplate(thisRecord);

    toast.info(`Template ${title} saved.`);

    return result;
  } else if (action.type == "__self/remove") {
    // await removeRaidData(store.getState().instanceID);
    await removeRaidData(store.getState().instanceID);
    route("/", true);
  }
  // check save
  if (
    action.type == "__self/forceSave" ||
    (!action.type.startsWith("__self") &&
      !action.type.split("/")[1].startsWith("_"))
  ) {
    console.log("Save", action.type);
    const stateData = { ...store.getState() };
    delete stateData.internalStatus;
    // const id = stateData.instanceID;
    updateRaidData(stateData);
  }

  return result;
};

const raidStatusReducer = (
  state = { isLoading: true, instanceNotFound: false },
  action
) => {
  switch (action.type) {
    case "raid/_status/setLoading":
      return {
        ...state,
        isLoading: true,
        instanceNotFound: false,
      };
    case "raid/_status/unsetLoading":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const reducers = combineReducers({
  records: recordReducer,
  extraRecords: extraRecordReducer,
  config: configReducer,
  instanceID: (state = nanoid(8)) => state,
  raidType: (state = RAID_TYPE.WOTLK_NAXX) => state,
  internalStatus: raidStatusReducer,
});

// const devTools = composeWithDevTools({});

// export const raidStore = createStore(
//   rootReducer,
//   devTools(applyMiddleware(localSave))
// );

// const loadInstance = async (instanceId, state) => {
//   console.log("load Instance", instanceId);
//   console.log("state", state);
//   const data = await getRaidData(instanceId) || state;
//   console.log("Raid Data", data);
//   return data;
// }

const rootReducer = (state, action) => {
  // console.log('root', state, action)
  if (action.type == "__self/setInstanceData") {
    const internalStatus = state.internalStatus;
    const templateData = action.payload;

    if (templateData.noInstance) {
      // id not find.
      state = {
        instanceID: templateData.instanceID,
      };
    } else if (templateData.mode == "default") {
      state = {
        instanceID: templateData.instanceID,
        raidType: templateData.raidType,
        config: {
          ...state.config,
          title: templateData.title,
          raidDate: Date.now(),
        },
      };
    } else {
      state = templateData;
    }

    state.internalStatus = {
      ...internalStatus,
      isLoading: false,
      instanceNotFound: templateData.noInstance,
    };
  }
  // else if (action.type == "__self/loadInstance"){
  //   state.internalStatus = {
  //     ...state.internalStatus,
  //     instanceNotFound: false,
  //     isLoading: true
  //   }
  // }
  return reducers(state, action);
};

export const createRaidStore = (initData = {}) => {
  const devTools = composeWithDevTools({});
  const store = createStore(
    rootReducer,
    initData,
    devTools(applyMiddleware(localSave))
  );
  return store;
};

export const raidStore = createRaidStore();
