import { RaidView } from "./components/RaidView";
import { ToastContainer } from "react-toastify";
import { Router, route } from "preact-router";
import "preact/debug";

import "react-toastify/dist/ReactToastify.css";
import "./scss/index.scss";
import "normalize.css";

import { nanoid } from "nanoid";
import { updateRaidData, getRaidData } from "~src/db/raidDB";
import { HomeView } from "~src/components/HomeView";

const Home = (props) => {
  const newRaid = async () => {
    console.log("new");
    const id = nanoid(8);
    const templateID = "uplkMVS9";
    const templateData = await getRaidData(templateID);
    templateData.instanceID = id;
    templateData.config.raidDate = Date.now();

    await updateRaidData(templateData);
    route(`/raid/${id}`, true);
  };
  const newRaidDefault = async () => {
    console.log("new default");
    const id = nanoid(8);
    const data = {
      instanceID: id,
      raidType: "TBC_BT",
      mode: "default",
    };
    await updateRaidData(data);
    route(`/raid/${id}`, true);
  };
  return (
    <div>
      <a href="#" onClick={newRaid}>
        new
      </a>
      <a href="#" onClick={newRaidDefault}>
        new(default)
      </a>
      <br />
      <a href="/raid/SbsazG7t">SbsazG7t</a>
      <a href="/raid/kwnwiJJ2">kwnwiJJ2</a>
      <br />
      <a href="/raid/2wnwiJJ2">kwnwiJ22</a>
      <a href="/raid/kwn1iJJ2">kwn1iJJ2</a>
    </div>
  );
};

import { raidStore } from "~src/store/raidStore";
function App() {
  const handleRoute = (e) => {
    // console.log(e);

    if (e.url.startsWith("/raid/")) {
      raidStore.dispatch({
        type: "raid/_status/setLoading",
      });
    }
  };
  return (
    <div className="App">
      <Router onChange={handleRoute}>
        {/*<Home path="/" />*/}
        <HomeView path="/" />
        <RaidView path="/raid/:instanceID" />
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        theme="dark"
      />
    </div>
  );
}

export default App;
