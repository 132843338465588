import html2canvas from 'html2canvas';

export function exportAsPng(element, title) {
    html2canvas(element, {useCORS: true}).then((canvas) => {
        let imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = `${title||"gdkp"}.png`;
        link.href = imgData;
        link.click();
        link.delete;
    });
}