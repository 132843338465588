import { useState, useRef, useContext, useEffect, useMemo } from "preact/hooks";
import { useSelector, shallowEqual, useDispatch, Provider } from "react-redux";
import { route } from "preact-router";

import { SVGIcon } from "~src/components/SVGIcon";
import {
  faSquareRss,
  faSkull,
  faTableLayout,
} from "@fortawesome/pro-solid-svg-icons";
import { Modal } from "~src/components/Modal";
import { RAID_NAME, RAID_TYPE } from "~src/const";

import { getTemplatesByType } from "~src/db/raidDB";
import { createRaidFromTemplate } from "~src/helper/raidTemplates";

import {
  homeStore,
  selectModalOpen,
  selectModalContent,
  toggleModal,
  setModalContent,
} from "~src/store/homeStore";

const RaidList = {
  wotlk: Object.entries(RAID_NAME).filter(([key]) => key.startsWith("WOTLK_")),
  tbc: Object.entries(RAID_NAME).filter(([key]) => key.startsWith("TBC_")),
};

const defaultRaid = RAID_TYPE.WOTLK_NAXX;
const ModalCreateNewRaid = (props) => {
  const { toggleOpen } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [raidOptions, setRaidOptions] = useState([]);
  const [title, setTitle] = useState("");
  const [raidType, setRaidType] = useState(defaultRaid);
  const [template, setTemplate] = useState("default");

  const loadTemplate = async (raidType) => {
    const templates = await getTemplatesByType(raidType);
    setRaidOptions(templates);
    setIsLoading(false);
  };

  const onRaidSelectChange = (e) => {
    setRaidType(e.target.value);
    setIsLoading(true);
    const ref = e.target;
    loadTemplate(ref.value);
  };

  const createRaid = async () => {
    const newRaidID = await createRaidFromTemplate(title, raidType, template);
    // console.log(newRaidID)
    toggleOpen();
    route(`/raid/${newRaidID}`);
  };

  useEffect(() => {
    console.log("defaultRaid");
    loadTemplate(defaultRaid);
  }, []);

  return {
    title: "New GDKP",
    extraButtons: [
      {
        cls: "is-primary",
        title: "Create",
        callback: async () => {
          createRaid();
        },
      },
    ],
    children: (
      <div class="raid-config-general">
        <div class="raid-config-row simple-input">
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faSquareRss} />
          </span>
          <p class="title">Raid Title</p>
          <div class="control">
            <input
              style="width: 20rem"
              class="input"
              placeholder="Raid Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div class="raid-config-row simple-input">
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faSkull} />
          </span>
          <p class="title">Raid Type</p>
          <div class="control">
            <select
              class="select"
              onChange={onRaidSelectChange}
              value={raidType}
            >
              <optgroup label="Wrath of the Lich King">
                {RaidList.wotlk.map((item) => (
                  <option value={item[0]}>{item[1].full}</option>
                ))}
              </optgroup>
              <optgroup label="The Burning Crusade">
                {RaidList.tbc.map((item) => (
                  <option value={item[0]}>{item[1].full}</option>
                ))}
              </optgroup>
            </select>
          </div>
        </div>
        <div class="raid-config-row simple-input">
          <span class="icon">
            <SVGIcon cls="icon" faSVG={faTableLayout} />
          </span>
          <p class="title">Template</p>
          <div class="control">
            <select
              class="select"
              value={template}
              onChange={(e) => setTemplate(e.target.value)}
            >
              {isLoading ? (
                <option selected disabled hidden>
                  Loading...
                </option>
              ) : (
                [
                  <option selected value="default">
                    Default
                  </option>,
                  raidOptions.map((item) => (
                    <option value={item[0]}>{item[1]}</option>
                  )),
                ]
              )}
            </select>
          </div>
        </div>
      </div>
    ),
  };
};

const ModalMap = {
  ModalCreateNewRaid,
};
const NOP = () => {};

export const ModalContainter = (props) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectModalOpen);
  const contextType = useSelector(selectModalContent);
  const toggleOpen = () => dispatch(toggleModal());

  let context = ModalMap[contextType] || NOP;

  const modalProps = {
    isOpen: isOpen,
    toggleOpen: toggleOpen,
    ...context({ toggleOpen }),
  };
  return <Modal {...modalProps} />;
};

export const createNewRaid = () => {
  homeStore.dispatch(setModalContent("ModalCreateNewRaid"));
};
