import CreatableSelect from "react-select/creatable";
import { useState, useContext, useEffect } from "preact/hooks";
import { Fragment } from "preact";
import { ITEM_TYPE, WOW_COLOR } from "../const";
import { RaidContext } from "../collection/RaidContext";
import { ItemContainter } from "./ItemContainter";

// import { isNumber } from "underscore";

const wowItemFormat = (data, conf) => {
  if (data.__isNew__)
    return (
      <div class="item-select__plain" style={{ color: WOW_COLOR[6] }}>
        {data.label}
      </div>
    );
  return <ItemContainter item_data={data} isDropdown={true} />;
};

const isNumber = (n) => {
  return /^\d+$/.test(n);
};

export const ItemSelect = (props) => {
  const { result, setResult, isOpen, toggleOpen } = props;

  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState({});

  const raidItemList = useContext(RaidContext);

  useEffect(() => {
    switch (result.type) {
      case ITEM_TYPE.STRING:
        setSearchText(result.value);
        break;
      case ITEM_TYPE.ITEM_ID:
        //setValue get item from list
        setValue(raidItemList[result.value]);
        break;
    }
  }, [result]);

  const itemFilter = (candidate, input) => {
    if (input) {
      if (candidate.data.__isNew__) return true;

      if (isNumber(input))
        return candidate.data.id && candidate.data.id.startsWith(input);
      else
        return (
          candidate.data.t &&
          candidate.data.t.toLowerCase().indexOf(input.toLowerCase()) > -1
        );
    }
    return true;
  };

  const isOptionSelected = (option, selectValue) => {
    return option.id == selectValue[0]?.id;
  };

  const handleChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case "select-option":
        setResult({ type: ITEM_TYPE.ITEM_ID, value: newValue.id });
        setSearchText("");
        break;
      case "clear":
        setResult({ type: ITEM_TYPE.STRING, value: "" });
        setSearchText("");
        break;
      case "create-option":
        setResult({ type: ITEM_TYPE.STRING, value: newValue.value });
        break;
      default:
        return;
    }
    setValue(newValue);
    toggleOpen();
  };

  const handleInputChange = (inputValue, actionMeta) => {
    switch (actionMeta.action) {
      case "input-change":
        setSearchText(inputValue);
        break;
      case "menu-close":
        toggleOpen();
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      {isOpen ? (
        <CreatableSelect
          className="item-select-containter"
          classNamePrefix="item-select"
          isClearable
          menuIsOpen
          autoFocus
          placeholder="Search..."
          components={{ DropdownIndicator: (e) => null }}
          controlShouldRenderValue={false}
          filterOption={itemFilter}
          hideSelectedOptions={false}
          formatOptionLabel={wowItemFormat}
          tabSelectsValue={false}
          onChange={handleChange}
          formatCreateLabel={(e) => e}
          onInputChange={handleInputChange}
          options={Object.values(raidItemList)}
          isOptionSelected={isOptionSelected}
          value={value}
          inputValue={searchText}
        />
      ) : null}
    </Fragment>
  );
};
