{
  "40437": {
    "t": "Concealment Shoulderpads",
    "i": "inv_shoulder_73",
    "s": "Shoulder, Leather Armor",
    "id": "40437",
    "q": 4,
    "tc": "潜伏护肩",
    "sc": "肩部, 皮甲"
  },
  "40439": {
    "t": "Mantle of the Eternal Sentinel",
    "i": "inv_shoulder_73",
    "s": "Shoulder, Leather Armor",
    "id": "40439",
    "q": 4,
    "tc": "永恒戒卫护肩",
    "sc": "肩部, 皮甲"
  },
  "40451": {
    "t": "Hyaline Helm of the Sniper",
    "i": "inv_helmet_18",
    "s": "Head, Mail Armor",
    "id": "40451",
    "q": 4,
    "tc": "狙击手的透明头盔",
    "sc": "头部, 锁甲"
  },
  "40438": {
    "t": "Council Chamber Epaulets",
    "i": "inv_shoulder_80",
    "s": "Shoulder, Mail Armor",
    "id": "40438",
    "q": 4,
    "tc": "议会大厅护肩",
    "sc": "肩部, 锁甲"
  },
  "40453": {
    "t": "Chestplate of the Great Aspects",
    "i": "inv_chest_plate18",
    "s": "Chest, Plate Armor",
    "id": "40453",
    "q": 4,
    "tc": "龙神胸铠",
    "sc": "胸部, 板甲"
  },
  "40446": {
    "t": "Dragon Brood Legguards",
    "i": "inv_pants_plate_21",
    "s": "Legs, Plate Armor",
    "id": "40446",
    "q": 4,
    "tc": "龙血护腿",
    "sc": "腿部, 板甲"
  },
  "40433": {
    "t": "Wyrmrest Band",
    "i": "inv_jewelry_ring_67",
    "s": "Rings",
    "id": "40433",
    "q": 4,
    "tc": "龙眠指环",
    "sc": "戒指"
  },
  "40431": {
    "t": "Fury of the Five Flights",
    "i": "spell_shadow_rainoffire",
    "s": "Trinkets",
    "id": "40431",
    "q": 4,
    "tc": "五色巨龙之怒",
    "sc": "饰品"
  },
  "40432": {
    "t": "Illustration of the Dragon Soul",
    "i": "inv_offhand_hyjal_d_01",
    "s": "Trinkets",
    "id": "40432",
    "q": 4,
    "tc": "龙魂图典",
    "sc": "饰品"
  },
  "40455": {
    "t": "Staff of Restraint",
    "i": "inv_staff_81",
    "s": "Two-Hand, Staff",
    "id": "40455",
    "q": 4,
    "tc": "节制法杖",
    "sc": "双手, 法杖"
  },
  "43345": {
    "t": "Dragon Hide Bag",
    "i": "inv_misc_bag_26_spellfire",
    "s": "Containers",
    "id": "43345",
    "q": 4,
    "tc": "龙皮包",
    "sc": "容器"
  },
  "43346": {
    "t": "Large Satchel of Spoils",
    "i": "inv_misc_bag_17",
    "s": "Junk",
    "id": "43346",
    "q": 4,
    "tc": "一大袋战利品",
    "sc": "垃圾"
  },
  "40628": {
    "t": "Gauntlets of the Lost Conqueror",
    "i": "inv_gauntlets_27",
    "s": "Paladin, Priest, Warlock",
    "id": "40628",
    "q": 4,
    "tc": "失落征服者的护手",
    "sc": "圣骑士, 牧师, 术士"
  },
  "40629": {
    "t": "Gauntlets of the Lost Protector",
    "i": "inv_gauntlets_27",
    "s": "Warrior, Hunter, Shaman",
    "id": "40629",
    "q": 4,
    "tc": "失落保卫者的护手",
    "sc": "战士, 猎人, 萨满"
  },
  "40630": {
    "t": "Gauntlets of the Lost Vanquisher",
    "i": "inv_gauntlets_27",
    "s": "Rogue, Deathk Night, Mage, Druid",
    "id": "40630",
    "q": 4,
    "tc": "失落胜利者的护手",
    "sc": "潜行者, 死亡骑士, 法师, 德鲁伊"
  },
  "44002": {
    "t": "The Sanctum's Flowing Vestments",
    "i": "inv_chest_cloth_63",
    "s": "Chest, Cloth Armor",
    "id": "44002",
    "q": 4,
    "tc": "圣殿熔流外衣",
    "sc": "胸部, 布甲"
  },
  "44003": {
    "t": "Upstanding Spaulders",
    "i": "inv_shoulder_73",
    "s": "Shoulder, Mail Armor",
    "id": "44003",
    "q": 4,
    "tc": "挺拔肩甲",
    "sc": "肩部, 锁甲"
  },
  "44004": {
    "t": "Bountiful Gauntlets",
    "i": "inv_gauntlets_69",
    "s": "Hands, Mail Armor",
    "id": "44004",
    "q": 4,
    "tc": "慷慨护手",
    "sc": "手, 锁甲"
  },
  "44000": {
    "t": "Dragonstorm Breastplate",
    "i": "inv_chest_plate20",
    "s": "Chest, Plate Armor",
    "id": "44000",
    "q": 4,
    "tc": "巨龙风暴胸铠",
    "sc": "胸部, 板甲"
  },
  "44005": {
    "t": "Pennant Cloak",
    "i": "inv_misc_cape_naxxramas_02",
    "s": "Cloaks",
    "id": "44005",
    "q": 4,
    "tc": "号旗披风",
    "sc": "斗篷"
  },
  "44008": {
    "t": "Unsullied Cuffs",
    "i": "inv_bracer_17",
    "s": "Wrist, Cloth Armor",
    "id": "44008",
    "q": 4,
    "tc": "清白裹腕",
    "sc": "手腕, 布甲"
  },
  "44007": {
    "t": "Headpiece of Reconciliation",
    "i": "inv_helmet_108",
    "s": "Head, Leather Armor",
    "id": "44007",
    "q": 4,
    "tc": "顺从头饰",
    "sc": "头部, 皮甲"
  },
  "44011": {
    "t": "Leggings of the Honored",
    "i": "inv_pants_mail_25",
    "s": "Legs, Leather Armor",
    "id": "44011",
    "q": 4,
    "tc": "荣耀者的护腿",
    "sc": "腿部, 皮甲"
  },
  "44006": {
    "t": "Obsidian Greathelm",
    "i": "inv_helmet_95",
    "s": "Head, Plate Armor",
    "id": "44006",
    "q": 4,
    "tc": "黑曜石巨盔",
    "sc": "头部, 板甲"
  },
  "43954": {
    "t": "Reins of the Twilight Drake",
    "i": "ability_mount_drake_twilight",
    "s": "Flying Mounts",
    "id": "43954",
    "q": 4,
    "tc": "暮光幼龙的缰绳",
    "sc": "飞行坐骑"
  }
}
