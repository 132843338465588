{
  "32239": {
    "tc": "唤海者便鞋",
    "t": "Slippers of the Seacaller",
    "s": "Feet, Cloth Armor",
    "i": "inv_boots_cloth_16",
    "q": 4,
    "sc": "脚, 布甲",
    "id": "32239"
  },
  "32240": {
    "tc": "海潮潜伏者之帽",
    "t": "Guise of the Tidal Lurker",
    "s": "Head, Leather Armor",
    "i": "inv_helmet_94",
    "q": 4,
    "sc": "头部, 皮甲",
    "id": "32240"
  },
  "32377": {
    "tc": "黑暗护肩",
    "t": "Mantle of Darkness",
    "s": "Shoulder, Leather Armor",
    "i": "inv_shoulder_67",
    "q": 4,
    "sc": "肩部, 皮甲",
    "id": "32377"
  },
  "32241": {
    "tc": "缓流头盔",
    "t": "Helm of Soothing Currents",
    "s": "Head, Mail Armor",
    "i": "inv_helmet_97",
    "q": 4,
    "sc": "头部, 锁甲",
    "id": "32241"
  },
  "32234": {
    "tc": "穆库之拳",
    "t": "Fists of Mukoa",
    "s": "Hands, Mail Armor",
    "i": "inv_gauntlets_59",
    "q": 4,
    "sc": "手, 锁甲",
    "id": "32234"
  },
  "32242": {
    "tc": "海洋之怒长靴",
    "t": "Boots of Oceanic Fury",
    "s": "Feet, Mail Armor",
    "i": "inv_boots_chain_12",
    "q": 4,
    "sc": "脚, 锁甲",
    "id": "32242"
  },
  "32232": {
    "tc": "恒金外壳护腕",
    "t": "Eternium Shell Bracers",
    "s": "Wrist, Plate Armor",
    "i": "inv_bracer_14",
    "q": 4,
    "sc": "手腕, 板甲",
    "id": "32232"
  },
  "32243": {
    "tc": "珍珠镶饰长靴",
    "t": "Pearl Inlaid Boots",
    "s": "Feet, Plate Armor",
    "i": "inv_boots_chain_08",
    "q": 4,
    "sc": "脚, 板甲",
    "id": "32243"
  },
  "32245": {
    "tc": "踏潮者胫甲",
    "t": "Tide-stomper's Greaves",
    "s": "Feet, Plate Armor",
    "i": "inv_boots_plate_04",
    "q": 4,
    "sc": "脚, 板甲",
    "id": "32245"
  },
  "32238": {
    "tc": "静浪之戒",
    "t": "Ring of Calming Waves",
    "s": "Rings",
    "i": "inv_jewelry_ring_57",
    "q": 4,
    "sc": "戒指",
    "id": "32238"
  },
  "32247": {
    "tc": "风暴掌控之戒",
    "t": "Ring of Captured Storms",
    "s": "Rings",
    "i": "inv_jewelry_ring_60",
    "q": 4,
    "sc": "戒指",
    "id": "32247"
  },
  "32237": {
    "tc": "大漩涡之怒",
    "t": "The Maelstrom's Fury",
    "s": "Main Hand, Dagger",
    "i": "inv_weapon_shortblade_58",
    "q": 4,
    "sc": "主手, 匕首",
    "id": "32237"
  },
  "32236": {
    "tc": "涨潮",
    "t": "Rising Tide",
    "s": "One-Hand, Axe",
    "i": "inv_axe_56",
    "q": 4,
    "sc": "单手, 斧",
    "id": "32236"
  },
  "32248": {
    "tc": "荒芜之戟",
    "t": "Halberd of Desolation",
    "s": "Two-Hand, Polearm",
    "i": "inv_weapon_halberd_20",
    "q": 4,
    "sc": "双手, 长柄武器",
    "id": "32248"
  },
  "32256": {
    "tc": "无尽腰带",
    "t": "Waistwrap of Infinity",
    "s": "Waist, Cloth Armor",
    "i": "inv_belt_03",
    "q": 4,
    "sc": "腰部, 布甲",
    "id": "32256"
  },
  "32252": {
    "tc": "虚空暗影外套",
    "t": "Nether Shadow Tunic",
    "s": "Chest, Leather Armor",
    "i": "inv_chest_leather_03",
    "q": 4,
    "sc": "胸部, 皮甲",
    "id": "32252"
  },
  "32259": {
    "tc": "风暴降临护腕",
    "t": "Bands of the Coming Storm",
    "s": "Wrist, Mail Armor",
    "i": "inv_bracer_02",
    "q": 4,
    "sc": "手腕, 锁甲",
    "id": "32259"
  },
  "32251": {
    "tc": "精准腕甲",
    "t": "Wraps of Precise Flight",
    "s": "Wrist, Mail Armor",
    "i": "inv_bracer_06",
    "q": 4,
    "sc": "手腕, 锁甲",
    "id": "32251"
  },
  "32258": {
    "tc": "自然庇护腰带",
    "t": "Naturalist's Preserving Cinch",
    "s": "Waist, Mail Armor",
    "i": "inv_belt_22",
    "q": 4,
    "sc": "腰部, 锁甲",
    "id": "32258"
  },
  "32250": {
    "tc": "深渊愤怒肩铠",
    "t": "Pauldrons of Abyssal Fury",
    "s": "Shoulder, Plate Armor",
    "i": "inv_shoulder_haremmatron_d_01",
    "q": 4,
    "sc": "肩部, 板甲",
    "id": "32250"
  },
  "32257": {
    "tc": "白鹿神像",
    "t": "Idol of the White Stag",
    "s": "Relic, Idols",
    "i": "inv_qirajidol_alabaster",
    "q": 4,
    "sc": "圣物, 神像",
    "id": "32257"
  },
  "32260": {
    "tc": "无尽梦魇项圈",
    "t": "Choker of Endless Nightmares",
    "s": "Amulets",
    "i": "inv_jewelry_necklace_35",
    "q": 4,
    "sc": "项链",
    "id": "32260"
  },
  "32261": {
    "tc": "深渊领主指环",
    "t": "Band of the Abyssal Lord",
    "s": "Rings",
    "i": "inv_jewelry_ring_70",
    "q": 4,
    "sc": "戒指",
    "id": "32261"
  },
  "32254": {
    "tc": "残酷者",
    "t": "The Brutalizer",
    "s": "One-Hand, Axe",
    "i": "inv_axe_59",
    "q": 4,
    "sc": "单手, 斧",
    "id": "32254"
  },
  "32262": {
    "tc": "纳斯雷兹姆吸血者",
    "t": "Syphon of the Nathrezim",
    "s": "One-Hand, Mace",
    "i": "inv_mace_44",
    "q": 4,
    "sc": "单手, 锤",
    "id": "32262"
  },
  "32255": {
    "tc": "魔石壁垒",
    "t": "Felstone Bulwark",
    "s": "Off Hand, Shields",
    "i": "inv_shield_38",
    "q": 4,
    "sc": "副手, 盾",
    "id": "32255"
  },
  "32253": {
    "tc": "军团杀手",
    "t": "Legionkiller",
    "s": "Ranged, Crossbow",
    "i": "inv_weapon_crossbow_20",
    "q": 4,
    "sc": "远程, 弩",
    "id": "32253"
  },
  "32273": {
    "tc": "耀眼光芒肩饰",
    "t": "Amice of Brilliant Light",
    "s": "Shoulder, Cloth Armor",
    "i": "inv_shoulder_63",
    "q": 4,
    "sc": "肩部, 布甲",
    "id": "32273"
  },
  "32270": {
    "tc": "法力聚焦护腕",
    "t": "Focused Mana Bindings",
    "s": "Wrist, Cloth Armor",
    "i": "inv_bracer_10",
    "q": 4,
    "sc": "手腕, 布甲",
    "id": "32270"
  },
  "32513": {
    "tc": "神圣感化护腕",
    "t": "Wristbands of Divine Influence",
    "s": "Wrist, Cloth Armor",
    "i": "inv_bracer_13",
    "q": 4,
    "sc": "手腕, 布甲",
    "id": "32513"
  },
  "32265": {
    "tc": "暗影行者腰带",
    "t": "Shadow-walker's Cord",
    "s": "Waist, Leather Armor",
    "i": "inv_belt_26",
    "q": 4,
    "sc": "腰部, 皮甲",
    "id": "32265"
  },
  "32271": {
    "tc": "不朽自然褶裙",
    "t": "Kilt of Immortal Nature",
    "s": "Legs, Leather Armor",
    "i": "inv_pants_leather_23",
    "q": 4,
    "sc": "腿部, 皮甲",
    "id": "32271"
  },
  "32264": {
    "tc": "隐藏猎手护肩",
    "t": "Shoulders of the Hidden Predator",
    "s": "Shoulder, Mail Armor",
    "i": "inv_shoulder_59",
    "q": 4,
    "sc": "肩部, 锁甲",
    "id": "32264"
  },
  "32275": {
    "tc": "灵魂行者护手",
    "t": "Spiritwalker Gauntlets",
    "s": "Hands, Mail Armor",
    "i": "inv_gauntlets_61",
    "q": 4,
    "sc": "手, 锁甲",
    "id": "32275"
  },
  "32276": {
    "tc": "闪火腰带",
    "t": "Flashfire Girdle",
    "s": "Waist, Mail Armor",
    "i": "inv_belt_13",
    "q": 4,
    "sc": "腰部, 锁甲",
    "id": "32276"
  },
  "32279": {
    "tc": "搜寻者的腕甲",
    "t": "The Seeker's Wristguards",
    "s": "Wrist, Plate Armor",
    "i": "inv_bracer_17",
    "q": 4,
    "sc": "手腕, 板甲",
    "id": "32279"
  },
  "32278": {
    "tc": "沉默的公正",
    "t": "Grips of Silent Justice",
    "s": "Hands, Plate Armor",
    "i": "inv_gauntlets_62",
    "q": 4,
    "sc": "手, 板甲",
    "id": "32278"
  },
  "32263": {
    "tc": "执政官护腿",
    "t": "Praetorian's Legguards",
    "s": "Legs, Plate Armor",
    "i": "inv_pants_plate_07",
    "q": 4,
    "sc": "腿部, 板甲",
    "id": "32263"
  },
  "32268": {
    "tc": "侍从战靴",
    "t": "Myrmidon's Treads",
    "s": "Feet, Plate Armor",
    "i": "inv_boots_plate_06",
    "q": 4,
    "sc": "脚, 板甲",
    "id": "32268"
  },
  "32266": {
    "tc": "欺诈指环",
    "t": "Ring of Deceitful Intent",
    "s": "Rings",
    "i": "inv_jewelry_ring_68",
    "q": 4,
    "sc": "戒指",
    "id": "32266"
  },
  "32361": {
    "tc": "盲眼先知圣像",
    "t": "Blind-Seers Icon",
    "s": "Off-hand Frills",
    "i": "inv_misc_orb_05",
    "q": 4,
    "sc": "副手",
    "id": "32361"
  },
  "32337": {
    "tc": "宽恕披风",
    "t": "Shroud of Forgiveness",
    "s": "Cloaks",
    "i": "inv_misc_cape_13",
    "q": 4,
    "sc": "斗篷",
    "id": "32337"
  },
  "32338": {
    "tc": "血咒护肩",
    "t": "Blood-cursed Shoulderpads",
    "s": "Shoulder, Cloth Armor",
    "i": "inv_shoulder_02",
    "q": 4,
    "sc": "肩部, 布甲",
    "id": "32338"
  },
  "32340": {
    "tc": "节制长袍",
    "t": "Garments of Temperance",
    "s": "Chest, Cloth Armor",
    "i": "inv_chest_cloth_35",
    "q": 4,
    "sc": "胸部, 布甲",
    "id": "32340"
  },
  "32339": {
    "tc": "原始王权腰带",
    "t": "Belt of Primal Majesty",
    "s": "Waist, Leather Armor",
    "i": "inv_belt_24",
    "q": 4,
    "sc": "腰部, 皮甲",
    "id": "32339"
  },
  "32334": {
    "tc": "迅疾突袭外套",
    "t": "Vest of Mounting Assault",
    "s": "Chest, Mail Armor",
    "i": "inv_chest_samurai",
    "q": 4,
    "sc": "胸部, 锁甲",
    "id": "32334"
  },
  "32342": {
    "tc": "坚定意志束带",
    "t": "Girdle of Mighty Resolve",
    "s": "Waist, Plate Armor",
    "i": "inv_belt_27",
    "q": 4,
    "sc": "腰部, 板甲",
    "id": "32342"
  },
  "32333": {
    "tc": "稳固腰带",
    "t": "Girdle of Stability",
    "s": "Waist, Plate Armor",
    "i": "inv_belt_33",
    "q": 4,
    "sc": "腰部, 板甲",
    "id": "32333"
  },
  "32341": {
    "tc": "神圣惩戒护腿",
    "t": "Leggings of Divine Retribution",
    "s": "Legs, Plate Armor",
    "i": "inv_pants_plate_20",
    "q": 4,
    "sc": "腿部, 板甲",
    "id": "32341"
  },
  "32335": {
    "tc": "凶猛进攻指环",
    "t": "Unstoppable Aggressor's Ring",
    "s": "Rings",
    "i": "inv_jewelry_ring_71",
    "q": 4,
    "sc": "戒指",
    "id": "32335"
  },
  "32501": {
    "tc": "影月徽记",
    "t": "Shadowmoon Insignia",
    "s": "Trinkets",
    "i": "inv_jewelcrafting_shadowsongamethyst_01",
    "q": 4,
    "sc": "饰品",
    "id": "32501"
  },
  "32269": {
    "tc": "命运的信使",
    "t": "Messenger of Fate",
    "s": "One-Hand, Dagger",
    "i": "inv_weapon_shortblade_63",
    "q": 4,
    "sc": "单手, 匕首",
    "id": "32269"
  },
  "32344": {
    "tc": "完美恢复法杖",
    "t": "Staff of Immaculate Recovery",
    "s": "Two-Hand, Staff",
    "i": "inv_staff_61",
    "q": 4,
    "sc": "双手, 法杖",
    "id": "32344"
  },
  "32343": {
    "tc": "棱光聚焦魔杖",
    "t": "Wand of Prismatic Focus",
    "s": "Ranged, Wand",
    "i": "inv_wand_16",
    "q": 4,
    "sc": "远程, 魔杖",
    "id": "32343"
  },
  "32353": {
    "tc": "不灭信仰手套",
    "t": "Gloves of Unfailing Faith",
    "s": "Hands, Cloth Armor",
    "i": "inv_gauntlets_63",
    "q": 4,
    "sc": "手, 布甲",
    "id": "32353"
  },
  "32351": {
    "tc": "艾露恩神力护腕",
    "t": "Elunite Empowered Bracers",
    "s": "Wrist, Leather Armor",
    "i": "inv_bracer_09",
    "q": 4,
    "sc": "手腕, 皮甲",
    "id": "32351"
  },
  "32347": {
    "tc": "诅咒之握",
    "t": "Grips of Damnation",
    "s": "Hands, Leather Armor",
    "i": "inv_gauntlets_65",
    "q": 4,
    "sc": "手, 皮甲",
    "id": "32347"
  },
  "32352": {
    "tc": "自然护卫者之靴",
    "t": "Naturewarden's Treads",
    "s": "Feet, Leather Armor",
    "i": "inv_boots_08",
    "q": 4,
    "sc": "脚, 皮甲",
    "id": "32352"
  },
  "32517": {
    "tc": "波浪治愈护肩",
    "t": "The Wavemender's Mantle",
    "s": "Shoulder, Mail Armor",
    "i": "inv_shoulder_61",
    "q": 4,
    "sc": "肩部, 锁甲",
    "id": "32517"
  },
  "32346": {
    "tc": "骨纹束带",
    "t": "Boneweave Girdle",
    "s": "Waist, Mail Armor",
    "i": "inv_belt_14",
    "q": 4,
    "sc": "腰部, 锁甲",
    "id": "32346"
  },
  "32354": {
    "tc": "命运皇冠",
    "t": "Crown of Empowered Fate",
    "s": "Head, Plate Armor",
    "i": "inv_helmet_96",
    "q": 4,
    "sc": "头部, 板甲",
    "id": "32354"
  },
  "32345": {
    "tc": "军团恐怖战靴",
    "t": "Dreadboots of the Legion",
    "s": "Feet, Plate Armor",
    "i": "inv_boots_plate_02",
    "q": 4,
    "sc": "脚, 板甲",
    "id": "32345"
  },
  "32349": {
    "tc": "透明魔线项链",
    "t": "Translucent Spellthread Necklace",
    "s": "Amulets",
    "i": "inv_jewelry_necklace_30",
    "q": 4,
    "sc": "项链",
    "id": "32349"
  },
  "32362": {
    "tc": "泰坦坠饰",
    "t": "Pendant of Titans",
    "s": "Amulets",
    "i": "inv_jewelry_amulet_03",
    "q": 4,
    "sc": "项链",
    "id": "32362"
  },
  "32332": {
    "tc": "诅咒火炬",
    "t": "Torch of the Damned",
    "s": "Two-Hand, Mace",
    "i": "inv_mace_49",
    "q": 4,
    "sc": "双手, 锤",
    "id": "32332"
  },
  "32363": {
    "tc": "纳鲁生命魔杖",
    "t": "Naaru-Blessed Life Rod",
    "s": "Ranged, Wand",
    "i": "inv_wand_15",
    "q": 4,
    "sc": "远程, 魔杖",
    "id": "32363"
  },
  "32350": {
    "tc": "灵感之触",
    "t": "Touch of Inspiration",
    "s": "Off-hand Frills",
    "i": "inv_misc_gem_ebondraenite_02",
    "q": 4,
    "sc": "副手",
    "id": "32350"
  },
  "32323": {
    "tc": "影月毁灭者斗篷",
    "t": "Shadowmoon Destroyer's Drape",
    "s": "Cloaks",
    "i": "inv_misc_cape_naxxramas_02",
    "q": 4,
    "sc": "斗篷",
    "id": "32323"
  },
  "32329": {
    "tc": "仁慈兜帽",
    "t": "Cowl of Benevolence",
    "s": "Head, Cloth Armor",
    "i": "inv_helmet_32",
    "q": 4,
    "sc": "头部, 布甲",
    "id": "32329"
  },
  "32327": {
    "tc": "暗影议会长袍",
    "t": "Robe of the Shadow Council",
    "s": "Chest, Cloth Armor",
    "i": "inv_chest_cloth_51",
    "q": 4,
    "sc": "胸部, 布甲",
    "id": "32327"
  },
  "32324": {
    "tc": "阴险护腕",
    "t": "Insidious Bands",
    "s": "Wrist, Leather Armor",
    "i": "inv_bracer_04",
    "q": 4,
    "sc": "手腕, 皮甲",
    "id": "32324"
  },
  "32328": {
    "tc": "植物学者的生长手套",
    "t": "Botanist's Gloves of Growth",
    "s": "Hands, Leather Armor",
    "i": "inv_gauntlets_24",
    "q": 4,
    "sc": "手, 皮甲",
    "id": "32328"
  },
  "32510": {
    "tc": "追踪软靴",
    "t": "Softstep Boots of Tracking",
    "s": "Feet, Mail Armor",
    "i": "inv_boots_chain_01",
    "q": 4,
    "sc": "脚, 锁甲",
    "id": "32510"
  },
  "32280": {
    "tc": "强制护手",
    "t": "Gauntlets of Enforcement",
    "s": "Hands, Plate Armor",
    "i": "inv_gauntlets_62",
    "q": 4,
    "sc": "手, 板甲",
    "id": "32280"
  },
  "32512": {
    "tc": "洛丹伦亡者腰带",
    "t": "Girdle of Lordaeron's Fallen",
    "s": "Waist, Plate Armor",
    "i": "inv_belt_28",
    "q": 4,
    "sc": "腰部, 板甲",
    "id": "32512"
  },
  "32330": {
    "tc": "先祖指引图腾",
    "t": "Totem of Ancestral Guidance",
    "s": "Relic, Totems",
    "i": "spell_nature_giftofthewaterspirit",
    "q": 4,
    "sc": "圣物, 图腾",
    "id": "32330"
  },
  "32326": {
    "tc": "萨拉克的扭曲飞刀",
    "t": "Twisted Blades of Zarak",
    "s": "Thrown, Thrown",
    "i": "inv_throwingknife_04",
    "q": 4,
    "sc": "投掷, 投掷武器",
    "id": "32326"
  },
  "32348": {
    "tc": "裂魂",
    "t": "Soul Cleaver",
    "s": "Two-Hand, Axe",
    "i": "inv_axe_60",
    "q": 4,
    "sc": "双手, 斧",
    "id": "32348"
  },
  "32325": {
    "tc": "坚韧卫士火枪",
    "t": "Rifle of the Stoic Guardian",
    "s": "Ranged, Gun",
    "i": "inv_weapon_rifle_21",
    "q": 4,
    "sc": "远程, 枪械",
    "id": "32325"
  },
  "32367": {
    "tc": "崩坏护腿",
    "t": "Leggings of Devastation",
    "s": "Legs, Cloth Armor",
    "i": "inv_pants_cloth_15",
    "q": 4,
    "sc": "腿部, 布甲",
    "id": "32367"
  },
  "32366": {
    "tc": "暗影主宰之靴",
    "t": "Shadowmaster's Boots",
    "s": "Feet, Leather Armor",
    "i": "inv_boots_cloth_02",
    "q": 4,
    "sc": "脚, 皮甲",
    "id": "32366"
  },
  "32365": {
    "tc": "裂心胸铠",
    "t": "Heartshatter Breastplate",
    "s": "Chest, Plate Armor",
    "i": "inv_chest_plate08",
    "q": 4,
    "sc": "胸部, 板甲",
    "id": "32365"
  },
  "32370": {
    "tc": "纳蒂娜的纯洁坠饰",
    "t": "Nadina's Pendant of Purity",
    "s": "Amulets",
    "i": "inv_jewelry_necklace_32",
    "q": 4,
    "sc": "项链",
    "id": "32370"
  },
  "32369": {
    "tc": "野蛮之刃",
    "t": "Blade of Savagery",
    "s": "One-Hand, Sword",
    "i": "inv_sword_87",
    "q": 4,
    "sc": "单手, 剑",
    "id": "32369"
  },
  "32368": {
    "tc": "光明使者宝典",
    "t": "Tome of the Lightbringer",
    "s": "Relic, Librams",
    "i": "inv_relics_libramofhope",
    "q": 4,
    "sc": "圣物, 圣契",
    "id": "32368"
  },
  "31101": {
    "tc": "遗忘征服者的护肩",
    "t": "Pauldrons of the Forgotten Conqueror",
    "s": "Armor Tokens",
    "i": "inv_shoulder_22",
    "q": 4,
    "sc": "护甲兑换",
    "id": "31101"
  },
  "31103": {
    "tc": "遗忘保卫者的护肩",
    "t": "Pauldrons of the Forgotten Protector",
    "s": "Armor Tokens",
    "i": "inv_shoulder_22",
    "q": 4,
    "sc": "护甲兑换",
    "id": "31103"
  },
  "31102": {
    "tc": "遗忘胜利者的护肩",
    "t": "Pauldrons of the Forgotten Vanquisher",
    "s": "Armor Tokens",
    "i": "inv_shoulder_22",
    "q": 4,
    "sc": "护甲兑换",
    "id": "31102"
  },
  "32331": {
    "tc": "伊利达雷议会披风",
    "t": "Cloak of the Illidari Council",
    "s": "Cloaks",
    "i": "inv_misc_cape_20",
    "q": 4,
    "sc": "斗篷",
    "id": "32331"
  },
  "32519": {
    "tc": "神圣指引腰带",
    "t": "Belt of Divine Guidance",
    "s": "Waist, Cloth Armor",
    "i": "inv_belt_07",
    "q": 4,
    "sc": "腰部, 布甲",
    "id": "32519"
  },
  "32518": {
    "tc": "旋叶护肩",
    "t": "Veil of Turning Leaves",
    "s": "Shoulder, Leather Armor",
    "i": "inv_shoulder_58",
    "q": 4,
    "sc": "肩部, 皮甲",
    "id": "32518"
  },
  "32376": {
    "tc": "森林潜伏者头盔",
    "t": "Forest Prowler's Helm",
    "s": "Head, Mail Armor",
    "i": "inv_helmet_95",
    "q": 4,
    "sc": "头部, 锁甲",
    "id": "32376"
  },
  "32373": {
    "tc": "伊利达雷碎裂头盔",
    "t": "Helm of the Illidari Shatterer",
    "s": "Head, Plate Armor",
    "i": "inv_helmet_98",
    "q": 4,
    "sc": "头部, 板甲",
    "id": "32373"
  },
  "32505": {
    "tc": "背叛者的疯狂",
    "t": "Madness of the Betrayer",
    "s": "Trinkets",
    "i": "spell_shadow_charm",
    "q": 4,
    "sc": "饰品",
    "id": "32505"
  },
  "31098": {
    "tc": "遗忘征服者的护腿",
    "t": "Leggings of the Forgotten Conqueror",
    "s": "Armor Tokens",
    "i": "inv_pants_plate_17",
    "q": 4,
    "sc": "护甲兑换",
    "id": "31098"
  },
  "31100": {
    "tc": "遗忘保卫者的护腿",
    "t": "Leggings of the Forgotten Protector",
    "s": "Armor Tokens",
    "i": "inv_pants_plate_17",
    "q": 4,
    "sc": "护甲兑换",
    "id": "31100"
  },
  "31099": {
    "tc": "遗忘胜利者的护腿",
    "t": "Leggings of the Forgotten Vanquisher",
    "s": "Armor Tokens",
    "i": "inv_pants_plate_17",
    "q": 4,
    "sc": "护甲兑换",
    "id": "31099"
  },
  "32524": {
    "tc": "上层精灵斗篷",
    "t": "Shroud of the Highborne",
    "s": "Cloaks",
    "i": "inv_misc_cape_06",
    "q": 4,
    "sc": "斗篷",
    "id": "32524"
  },
  "32525": {
    "tc": "伊利达雷大领主兜帽",
    "t": "Cowl of the Illidari High Lord",
    "s": "Head, Cloth Armor",
    "i": "inv_helmet_53",
    "q": 4,
    "sc": "头部, 布甲",
    "id": "32525"
  },
  "32235": {
    "tc": "萨格拉斯的诅咒视界",
    "t": "Cursed Vision of Sargeras",
    "s": "Head, Leather Armor",
    "i": "inv_misc_bandana_03",
    "q": 4,
    "sc": "头部, 皮甲",
    "id": "32235"
  },
  "32521": {
    "tc": "铁壁面甲",
    "t": "Faceplate of the Impenetrable",
    "s": "Head, Plate Armor",
    "i": "inv_helmet_98",
    "q": 4,
    "sc": "头部, 板甲",
    "id": "32521"
  },
  "32497": {
    "tc": "怒风徽记之戒",
    "t": "Stormrage Signet Ring",
    "s": "Rings",
    "i": "inv_jewelry_ring_67",
    "q": 4,
    "sc": "戒指",
    "id": "32497"
  },
  "32483": {
    "tc": "古尔丹之颅",
    "t": "The Skull of Gul'dan",
    "s": "Trinkets",
    "i": "inv_misc_bone_elfskull_01",
    "q": 4,
    "sc": "饰品",
    "id": "32483"
  },
  "32496": {
    "tc": "泰兰德的记忆",
    "t": "Memento of Tyrande",
    "s": "Trinkets",
    "i": "inv_summerfest_fireflower",
    "q": 4,
    "sc": "饰品",
    "id": "32496"
  },
  "32837": {
    "tc": "埃辛诺斯战刃",
    "t": "Warglaive of Azzinoth",
    "s": "Main Hand, Sword",
    "i": "inv_weapon_glave_01",
    "q": 5,
    "sc": "主手, 剑",
    "id": "32837"
  },
  "32838": {
    "tc": "埃辛诺斯战刃",
    "t": "Warglaive of Azzinoth",
    "s": "Off Hand, Sword",
    "i": "inv_weapon_glave_01",
    "q": 5,
    "sc": "副手, 剑",
    "id": "32838"
  },
  "31089": {
    "tc": "遗忘征服者的胸甲",
    "t": "Chestguard of the Forgotten Conqueror",
    "s": "Armor Tokens",
    "i": "inv_chest_chain_03",
    "q": 4,
    "sc": "护甲兑换",
    "id": "31089"
  },
  "31091": {
    "tc": "遗忘保卫者的胸甲",
    "t": "Chestguard of the Forgotten Protector",
    "s": "Armor Tokens",
    "i": "inv_chest_chain_03",
    "q": 4,
    "sc": "护甲兑换",
    "id": "31091"
  },
  "31090": {
    "tc": "遗忘胜利者的胸甲",
    "t": "Chestguard of the Forgotten Vanquisher",
    "s": "Armor Tokens",
    "i": "inv_chest_chain_03",
    "q": 4,
    "sc": "护甲兑换",
    "id": "31090"
  },
  "32471": {
    "tc": "埃辛诺斯碎片",
    "t": "Shard of Azzinoth",
    "s": "One-Hand, Dagger",
    "i": "inv_weapon_shortblade_62",
    "q": 4,
    "sc": "单手, 匕首",
    "id": "32471"
  },
  "32500": {
    "tc": "卡拉波水晶之塔",
    "t": "Crystal Spire of Karabor",
    "s": "Main Hand, Mace",
    "i": "inv_mace_56",
    "q": 4,
    "sc": "主手, 锤",
    "id": "32500"
  },
  "32374": {
    "tc": "扎尔杜姆，吞噬者的法杖",
    "t": "Zhar'doom, Greatstaff of the Devourer",
    "s": "Two-Hand, Staff",
    "i": "inv_staff_59",
    "q": 4,
    "sc": "双手, 法杖",
    "id": "32374"
  },
  "32375": {
    "tc": "埃辛诺斯壁垒",
    "t": "Bulwark of Azzinoth",
    "s": "Off Hand, Shields",
    "i": "inv_shield_32",
    "q": 4,
    "sc": "副手, 盾",
    "id": "32375"
  },
  "32336": {
    "tc": "背叛者的黑暗之弓",
    "t": "Black Bow of the Betrayer",
    "s": "Ranged, Bow",
    "i": "inv_weapon_bow_31",
    "q": 4,
    "sc": "远程, 弓",
    "id": "32336"
  },
  "32590": {
    "tc": "空灵披风",
    "t": "Nethervoid Cloak",
    "s": "Cloaks",
    "i": "inv_misc_cape_10",
    "q": 4,
    "sc": "斗篷",
    "id": "32590"
  },
  "34012": {
    "tc": "决战披风",
    "t": "Shroud of the Final Stand",
    "s": "Cloaks",
    "i": "inv_misc_cape_16",
    "q": 4,
    "sc": "斗篷",
    "id": "34012"
  },
  "32609": {
    "tc": "神圣光芒长靴",
    "t": "Boots of the Divine Light",
    "s": "Feet, Cloth Armor",
    "i": "inv_boots_cloth_08",
    "q": 4,
    "sc": "脚, 布甲",
    "id": "32609"
  },
  "32593": {
    "tc": "兽穴领袖之靴",
    "t": "Treads of the Den Mother",
    "s": "Feet, Leather Armor",
    "i": "inv_boots_wolf",
    "q": 4,
    "sc": "脚, 皮甲",
    "id": "32593"
  },
  "32592": {
    "tc": "无情风暴胸甲",
    "t": "Chestguard of Relentless Storms",
    "s": "Chest, Mail Armor",
    "i": "inv_chest_chain_11",
    "q": 4,
    "sc": "胸部, 锁甲",
    "id": "32592"
  },
  "32608": {
    "tc": "掠夺护手",
    "t": "Pillager's Gauntlets",
    "s": "Hands, Plate Armor",
    "i": "inv_gauntlets_62",
    "q": 4,
    "sc": "手, 板甲",
    "id": "32608"
  },
  "32606": {
    "tc": "圣光使者腰带",
    "t": "Girdle of the Lightbearer",
    "s": "Waist, Plate Armor",
    "i": "inv_belt_28",
    "q": 4,
    "sc": "腰部, 板甲",
    "id": "32606"
  },
  "32591": {
    "tc": "锯齿利刃项圈",
    "t": "Choker of Serrated Blades",
    "s": "Amulets",
    "i": "inv_jewelry_necklace_34",
    "q": 4,
    "sc": "项链",
    "id": "32591"
  },
  "32589": {
    "tc": "地狱火坠饰",
    "t": "Hellfire-Encased Pendant",
    "s": "Amulets",
    "i": "inv_jewelry_necklace_17",
    "q": 4,
    "sc": "项链",
    "id": "32589"
  },
  "32526": {
    "tc": "浩劫指环",
    "t": "Band of Devastation",
    "s": "Rings",
    "i": "inv_jewelry_ring_35",
    "q": 4,
    "sc": "戒指",
    "id": "32526"
  },
  "32528": {
    "tc": "卡拉波祝福指环",
    "t": "Blessed Band of Karabor",
    "s": "Rings",
    "i": "inv_jewelry_ring_35",
    "q": 4,
    "sc": "戒指",
    "id": "32528"
  },
  "32527": {
    "tc": "远古知识之戒",
    "t": "Ring of Ancient Knowledge",
    "s": "Rings",
    "i": "inv_jewelry_ring_35",
    "q": 4,
    "sc": "戒指",
    "id": "32527"
  },
  "34009": {
    "tc": "审判之锤",
    "t": "Hammer of Judgement",
    "s": "Main Hand, Mace",
    "i": "inv_mace_57",
    "q": 4,
    "sc": "主手, 锤",
    "id": "34009"
  },
  "32943": {
    "tc": "迅钢锤",
    "t": "Swiftsteel Bludgeon",
    "s": "One-Hand, Mace",
    "i": "inv_mace_44",
    "q": 4,
    "sc": "单手, 锤",
    "id": "32943"
  },
  "34011": {
    "tc": "伊利达雷符文盾",
    "t": "Illidari Runeshield",
    "s": "Off Hand, Shields",
    "i": "inv_shield_01",
    "q": 4,
    "sc": "副手, 盾",
    "id": "34011"
  }
}
